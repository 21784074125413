import React from "react";
import { compose, withHooks, defaultProps, withTranslation } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Address,
  Button,
  RadioGroup,
} from "components";
import { get, range } from "lodash";

const AddressListComponent = (props: any) => (
  <Box {...props}>
    <Box display="flex" alignItems="center" mb={6}>
      <Typography variant="h4" flex={1}>
        {props.title}
      </Typography>
      <Typography variant="body1">
        {props.t(".total", { amount: props.amount })}
      </Typography>
    </Box>
    {range(props.amount).map((index) => {
      return (
        <Box key={index}>
          <Box display="flex" alignItems="center" mt={6}>
            <Typography variant="h6" bold flex={1}>
              {props.t(".addressNo", { index: index + 1 })}
            </Typography>
            <Button
              startIcon="bigTrash"
              onClick={() => {
                props.remove(index);
              }}
            />
          </Box>
          <Field
            component={Address}
            name={`${props.name}[${index}]`}
            mt={6}
            type={props.type}
            hasGmap={props.hasGmap}
          />
          <Field
            component={RadioGroup}
            name={`${props.name}[${index}].isMain`}
            options={[
              {
                label: "ใช้เป็นที่อยู่หลัก",
                value: "true",
              },
            ]}
            onChange={props.selectMainAddress(index)}
            mt={6}
            mb={6}
          />
        </Box>
      );
    })}
    <Button startIcon="add" onClick={props.push}>
      {props.addButtonLabel}
    </Button>
  </Box>
);

const enhancer = compose(
  withTranslation({ prefix: "components.advance.AddressList" }),
  withHooks((props: any, hooks: any) => {
    const {
      form,
      name,
      push,
      remove,
      title,
      addButtonLabel,
      mt,
      type,
      hasGmap,
    } = props;
    const { useCallback } = hooks;
    const amount = get(form.values, name)?.length ?? 0;

    const customPush = useCallback(() => {
      push({});
    }, [push]);

    const customRemove = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove]
    );

    const setFieldValue = form.setFieldValue;
    const selectMainAddress = useCallback(
      (selectedIndex: number) => () => {
        range(amount).forEach((index) => {
          setFieldValue(
            `${name}[${index}].isMain`,
            index === selectedIndex ? "true" : null
          );
        });
      },
      [setFieldValue, name, amount]
    );

    return {
      name,
      amount,
      push: customPush,
      remove: customRemove,
      selectMainAddress,
      title,
      addButtonLabel,
      mt,
      type,
      hasGmap,
    };
  })
);

const AddressList = enhancer(AddressListComponent);

AddressList.Billing = defaultProps({ type: "billing" })(AddressList);
AddressList.Delivery = defaultProps({ type: "delivery" })(AddressList);

export default AddressList;
