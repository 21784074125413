import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as CopyIcon } from "assets/icon/copy-icon.svg";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as ViewIcon } from "assets/icon/view-icon.svg";
import {
  Box,
  Button,
  Field,
  Form,
  Grid,
  Modal,
  Select,
  Table,
  TextField,
  Typography,
} from "components";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { EnumMasterInsuranceSubState } from "constants/enums/master-insurance-sub-state";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import insuranceStore from "stores/insuranceStore";
import { AppColor } from "theme/app-color";
import { gql, homePath, notifySuccess, paths } from "utils/helper";
import AddInsuranceModal from "./AddInsuranceModal";
import { handleNavigateInsuranceStep } from "./insuranceRoutes";

const InsuranceLandingComponent = (props: any) => (
  <>
    <PageContent
      title={props.t(".title")}
      breadcrumbs={props.breadcrumbs}
      pageActions={props.hasEditPermission ? props.pageActions : []}
    >
      <Form>
        <Box width="100%" mb={-6}>
          <Typography variant="h4" mb={4}>
            {props.t(".list")}
          </Typography>
          <Grid container spacing={6} mt="24px" mb="24px" alignItems="center">
            <Grid item xs={12} sm={5}>
              <Field
                component={Select}
                name="state"
                type="text"
                label={props.t(".status")}
                options={props.stateOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Field
                component={TextField}
                name="year"
                type="number"
                label={props.t(".year")}
                fullWidth
                fast={false}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box display="flex" justifyContent="flex-end" width="100%">
                <Button
                  mr="16px"
                  style={{ border: "none" }}
                  onClick={props.handleResetFilter}
                  variant="text"
                >
                  {props.t(".reset")}
                </Button>
                <Button variant="contained" onClick={props.handleFilter}>
                  {props.t(".filter")}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Table
            columns={props.columns}
            rows={props.tableDataI18n}
            loading={props.loading}
            onRowClick={(params: any) => {
              props.handleConfirmEdit(params.id, params.row.subState);
            }}
            density="compact"
            autoHeight
            isFilter={props.isFilter}
            disableSelectionOnClick
          />
        </Box>
      </Form>
    </PageContent>
    <AddInsuranceModal
      isOpen={props.isAddInsuranceModalOpen}
      onCancel={props.handleCloseAddInsurancesModal}
    />
  </>
);

export const API = {
  FETCH_MASTER_INSURANCES: gql`
    query FETCH_MASTER_INSURANCES($year: Float, $state: String) {
      masterInsurances(input: { year: $year, state: $state }) {
        id
        year
        state
        subState
        updatedAt
        insurances {
          insuranceType {
            symbol
            nameTh
            nameEn
          }
        }
        isEnrollmentPublished
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  DUPLICATE_MASTER_INSURANCE: gql`
    mutation DUPLICATE_MASTER_INSURANCE($id: String!, $createYear: Float!) {
      duplicateMasterInsurance(input: { id: $id, createYear: $createYear }) {
        id
        year
      }
    }
  `,
  CHANGE_TO_RE_EDITING: gql`
    mutation CHANGE_TO_RE_EDITING($id: String!) {
      changeToEditing(id: $id) {
        id
        year
        state
      }
    }
  `,
  DELETE_MASTER_INSURANCE: gql`
    mutation DELETE_MASTER_INSURANCE($id: String!) {
      deleteMasterInsurance(input: { id: $id }) {
        id
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      state: "all",
    }),
  }),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
    cleared: stores.insuranceStore.cleared,
  })),
  withTranslation({ prefix: "pages.main.insurances.index" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useMutation,
      useEffect,
      useState,
      useDataTranslation,
    } = hooks;
    const { currentUser, values, setValues, cleared, t } = props;
    const { data: insuranceTypeData = {} } = useQuery(
      API.FETCH_INSURANCE_TYPES,
      {
        fetchPolicy: "network-only",
      }
    );
    const {
      loading,
      data,
      error,
      refetch,
    } = useQuery(API.FETCH_MASTER_INSURANCES, { fetchPolicy: "network-only" });
    const [duplicateIns] = useMutation(API.DUPLICATE_MASTER_INSURANCE);
    const [deleteIns] = useMutation(API.DELETE_MASTER_INSURANCE);
    const [changePublishedToReEditing] = useMutation(API.CHANGE_TO_RE_EDITING);
    const [isAddInsuranceModalOpen, setIsAddInsuranceModalOpen] = useState(
      false
    );
    const [isFilter, setIsFilter] = useState(false);

    const insuranceTypes = insuranceTypeData?.insuranceTypes || [];

    const title = t(".title");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);

    const deleteInsurance = useCallback(
      async (params: any) => {
        const { id, year } = params.row;
        Modal.open({
          title: t(".deleteTitle"),
          children: (
            <Box mb={6} display="flex">
              <Typography variant="body1" color="#767676">
                {t(".deleteDescription", { year })}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".cancel"),
          okButtonLabel: t(".deleteTitle"),
          okButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            try {
              await deleteIns({
                variables: {
                  id: id,
                },
              });
              await refetch({});
              close();
              notifySuccess(t(".deleteSuccess"));
            } catch (e: any) {
              close();
            }
          },
        });
      },
      [deleteIns, t, refetch]
    );

    const duplicateInsurance = useCallback(
      async (params: any) => {
        const { id, year } = params.row;

        Modal.open({
          title: t(".duplicateTitle"),
          children: (
            <Box mb={6} display="flex">
              <Typography variant="body1" color="#767676">
                {t(".duplicateDescription", {
                  year: year,
                  secondYear: data?.masterInsurances[0].year + 1,
                })}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".cancel"),
          okButtonLabel: t(".duplicateTitle"),
          okButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await duplicateIns({
              variables: {
                id: id,
                createYear: data?.masterInsurances[0].year + 1,
              },
            });
            await refetch({});
            close();
            notifySuccess(t(".duplicateSuccess"));
          },
        });
      },
      [refetch, t, duplicateIns, data]
    );

    const viewInsurance = useCallback(async (params: any) => {
      const { id, subState } = params.row;
      handleNavigateInsuranceStep(id, subState);
    }, []);

    const handleConfirmEdit = useCallback(
      (id: string, subState: EnumMasterInsuranceSubState) => {
        handleNavigateInsuranceStep(id, subState);
      },
      []
    );

    const editInsurance = useCallback(
      async (params: any) => {
        const { id, state, subState } = params.row;
        if (state === EnumMasterInsuranceState.published) {
          Modal.open({
            title: t(".reEditing"),
            children: (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="body1"
                    color={AppColor["Text/Dark Grey"]}
                  >
                    {t(".reEditingInfo")}
                  </Typography>
                </div>
              </>
            ),
            cancelButtonLabel: t(".cancel"),
            okButtonLabel: t(".edit"),
            okButtonVariant: "outlined",
            onOk: async ({ close }: any) => {
              await changePublishedToReEditing({ variables: { id } });
              handleConfirmEdit(id, subState);
              close();
            },
          });
        } else {
          handleConfirmEdit(id, subState);
        }
      },
      [t, handleConfirmEdit, changePublishedToReEditing]
    );
    const hasEditPermission = ["INSURANCE_MANAGEMENT_EDIT"].every((perm) =>
      currentUser?.role?.permissions.includes(perm)
    );

    const conditionAction = useCallback(
      (state: EnumMasterInsuranceState, isEnrollmentPublished: boolean) => {
        switch (state) {
          case EnumMasterInsuranceState.published:
            return isEnrollmentPublished
              ? [
                  {
                    Icon: CopyIcon,
                    onClick: duplicateInsurance,
                  },
                  {
                    Icon: ViewIcon,
                    onClick: viewInsurance,
                  },
                ]
              : [
                  {
                    Icon: CopyIcon,
                    onClick: duplicateInsurance,
                  },
                  {
                    Icon: EditIcon,
                    onClick: editInsurance,
                  },
                ];
          case EnumMasterInsuranceState.draft:
            return [
              {
                Icon: CopyIcon,
                onClick: duplicateInsurance,
              },
              {
                Icon: EditIcon,
                onClick: editInsurance,
              },
              {
                Icon: RedTrashIcon,
                onClick: deleteInsurance,
              },
            ];
          default:
            return [
              {
                Icon: CopyIcon,
                onClick: duplicateInsurance,
              },
              {
                Icon: EditIcon,
                onClick: editInsurance,
              },
            ];
        }
      }
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data?.masterInsurances?.map((masterInsurance: any) => {
        let amountTh = "";
        let amountEn = "";
        let i = 0;

        for (const insuranceType of insuranceTypes) {
          const count = masterInsurance?.insurances?.filter(
            (insurance: any) =>
              insurance?.insuranceType?.symbol === insuranceType.symbol
          ).length;
          i++;
          amountTh +=
            insuranceType.nameTh +
            " - " +
            count +
            (i === insuranceTypes?.length ? "" : " / ");
          amountEn +=
            insuranceType.nameEn +
            " - " +
            count +
            (i === insuranceTypes?.length ? "" : " / ");
        }

        return {
          ...masterInsurance,
          state: masterInsurance.state,
          amountTh,
          amountEn,
          isEnrollmentPublished: masterInsurance.isEnrollmentPublished,
          actions: hasEditPermission
            ? conditionAction(
                masterInsurance.state,
                masterInsurance.isEnrollmentPublished
              )
            : [],
        };
      });
    }, [
      loading,
      error,
      data?.masterInsurances,
      hasEditPermission,
      conditionAction,
      insuranceTypes,
    ]);

    const tableDataI18n = useDataTranslation(tableData);

    const handleOpenAddInsurancesModal = useCallback(() => {
      setIsAddInsuranceModalOpen(true);
    }, [setIsAddInsuranceModalOpen]);

    const handleCloseAddInsurancesModal = useCallback(() => {
      setIsAddInsuranceModalOpen(false);
    }, [setIsAddInsuranceModalOpen]);

    const pageActions = useMemo(
      () => [
        {
          children: t(".addButton"),
          startIcon: <AddIcon />,
          onClick: () => handleOpenAddInsurancesModal(),
          color: "primary",
          permittedRoles: ["INSURANCE_MANAGEMENT_EDIT"],
        },
      ],
      [t, handleOpenAddInsurancesModal]
    );

    const columns = useMemo(
      (): GridColDef[] => [
        {
          width: 335,
          field: "year",
          headerName: t(".year") || "",
        },
        {
          width: 335,
          field: "amount",
          headerName: t(".amount") || "",
        },
        {
          width: 150,
          field: "updatedAt",
          headerName: t(".updatedAt") || "",
          type: "dateOnly",
        },
        {
          width: 100,
          field: "state",
          headerName: t(".status") || "",
          renderCell: (params: any) => {
            return t(`.state.${params.row.state}`);
          },
        },
        {
          width: 140,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t]
    );

    const stateOptions = useMemo(() => {
      return [
        { label: t(".state.all"), value: "all" },
        { label: t(".state.draft"), value: EnumMasterInsuranceState.draft },
        {
          label: t(".state.published"),
          value: EnumMasterInsuranceState.published,
        },
        { label: t(".state.editing"), value: EnumMasterInsuranceState.editing },
      ];
    }, [t]);

    const handleFilter = useCallback(async () => {
      await refetch({
        state: values.state,
        year: parseInt(values.year) || null,
      });
      setIsFilter(true);
    }, [values, refetch, setIsFilter]);

    const handleResetFilter = useCallback(async () => {
      setValues({ state: "all", year: "" });
      await refetch({ state: "all", year: null });
      setIsFilter(false);
    }, [refetch, setValues, setIsFilter]);

    useEffect(() => {
      if (!cleared) insuranceStore.clearData();
    }, [cleared]);

    return {
      breadcrumbs,
      tableData,
      tableDataI18n,
      columns,
      pageActions,
      hasEditPermission,
      isAddInsuranceModalOpen,
      handleCloseAddInsurancesModal,
      loading,
      stateOptions,
      handleFilter,
      handleResetFilter,
      editInsurance,
      isFilter,
      handleConfirmEdit,
    };
  })
);

export const InsuranceLandingPage = enhancer(InsuranceLandingComponent);
