import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { gql, homePath } from "utils/helper";
import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { removeLoggingAd, setRefreshToken, setToken } from "api";
import { useEffect } from "react";
import appStore from "stores/appStore";
import { Notification } from "components";

interface HandleAdRedirectPageProps {}

const HandleAdRedirectPageComponent = (props: HandleAdRedirectPageProps) =>
  null;

const API = {
  SIGN_IN_WITH_AZURE_AD: gql`
    mutation SIGN_IN_WITH_AZURE_AD($email: String!) {
      signInWithAzureAd(email: $email) {
        currentUser {
          id
          email
          authenticationToken
          role {
            permissions
          }
        }
        accessToken
        refreshToken
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.authentication.LoginPage" }),
  withHooks((props: any, hooks: any) => {
    const { useMutation } = hooks;
    const { t } = props;
    const { instance } = useMsal();

    const [signInWithAzureAd] = useMutation(API.SIGN_IN_WITH_AZURE_AD, {
      onCompleted: (data: any) => {
        const {
          accessToken,
          refreshToken,
          currentUser,
        } = data.signInWithAzureAd;
        appStore.setCurrentUser(data.verifyBackofficeUserViaOtp);
        setToken(accessToken);
        setRefreshToken(refreshToken);
        removeLoggingAd();
        window.location.href = homePath(currentUser.role.permissions);
      },
      onError: (data: any) => {
        const message =
          data?.networkError?.result?.errors[0]?.extensions?.originalError[0]
            ?.message;
        if (message) {
          Notification.error(t(message));
        }
        appStore.logout();
      },
    });

    useEffect(() => {
      const callbackId = instance.addEventCallback(async (message: any) => {
        const eventType: EventType = message.eventType;
        switch (eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS: {
            instance.setActiveAccount(message.payload);
            break;
          }
          case EventType.HANDLE_REDIRECT_END: {
            const account = instance.getActiveAccount();
            if (account) {
              await signInWithAzureAd({
                variables: {
                  email: account.idTokenClaims?.preferred_username,
                },
              });
            }
            break;
          }
          default:
            break;
        }
      });

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    }, [instance, signInWithAzureAd]);

    return {};
  })
);

export const HandleAdRedirectPage = enhancer(HandleAdRedirectPageComponent);
