import { SvgIconProps } from "@material-ui/core";
import * as MuiIcon from "@material-ui/icons";
import { withTranslation } from "enhancers";
import React from "react";
import { AppColor } from "theme/app-color";

export type MuiIconCollections = keyof typeof MuiIcon;

interface CustomIconProps extends SvgIconProps {
  name?: MuiIconCollections;
  t: Function;
}

export const MaterialIcon = withTranslation({
  prefix: "components.common.MaterialIcon",
})((props: CustomIconProps) => {
  const { name, t, ...rest } = props;
  if (MuiIcon[name as MuiIconCollections])
    return React.createElement(MuiIcon[name as MuiIconCollections], {
      ...rest,
    });
  else return <>{t(".notFoundIcon")}</>;
});
