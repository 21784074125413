export enum PERMISSIONS {
  SYNCING_NOTIFICATION = "SYNCING_NOTIFICATION",
  ADMIN_MANAGEMENT_READ = "ADMIN_MANAGEMENT_READ",
  ADMIN_MANAGEMENT_EDIT = "ADMIN_MANAGEMENT_EDIT",
  PERMISSION_MANAGEMENT_READ = "PERMISSION_MANAGEMENT_READ",
  PERMISSION_MANAGEMENT_EDIT = "PERMISSION_MANAGEMENT_EDIT",
  ENROLLMENT_MANAGEMENT_READ = "ENROLLMENT_MANAGEMENT_READ",
  ENROLLMENT_MANAGEMENT_EDIT = "ENROLLMENT_MANAGEMENT_EDIT",
  REPORT_MANAGEMENT_READ = "REPORT_MANAGEMENT_READ",
  REPORT_MANAGEMENT_EDIT = "REPORT_MANAGEMENT_EDIT",
  INSURANCE_MANAGEMENT_READ = "INSURANCE_MANAGEMENT_READ",
  INSURANCE_MANAGEMENT_EDIT = "INSURANCE_MANAGEMENT_EDIT",
  SETTING_MANAGEMENT_READ = "SETTING_MANAGEMENT_READ",
  SETTING_MANAGEMENT_EDIT = "SETTING_MANAGEMENT_EDIT",
}
