import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { useTranslation } from "react-i18next";
import { Box, Field, Form } from ".";
import Select from "./Select";
import { getCurrentLang } from "common/i18next";

const LanguagesDropdown = (props: any) => (
  <Box
    position={props.position ? props.position : "fixed"}
    top={20}
    right={10}
    width={120}
    ml={2}
  >
    <Form>
      <Field
        component={Select}
        name="language"
        type="text"
        label={props.t(".label")}
        options={props.languageOptions}
        size="small"
        disableClearable
        fast={false}
      />
    </Form>
  </Box>
);

const enhancer = compose(
  withFormik({
    mapPropsToValues: (props: any) => ({
      language: getCurrentLang(),
    }),
  }),
  withTranslation({ prefix: "components.common.languagesDropdown" }),
  withHooks((props: any, hooks: any) => {
    const { values, position } = props;
    const { useMemo, useEffect } = hooks;
    const { i18n } = useTranslation();
    const languageOptions = useMemo(
      () => [
        { label: "ไทย", value: "th" },
        { label: "English", value: "en" },
      ],
      []
    );

    useEffect(() => {
      if (i18n.language !== values.language) {
        i18n.changeLanguage(values.language);
      }
    }, [i18n, values.language]);

    return { languageOptions, position };
  })
);

export default enhancer(LanguagesDropdown);
