import {
  compose,
  withHooks,
  withFormik,
  withTranslation,
  withStores,
} from "enhancers";
import { Notification } from "components";
import { getErrorMessage, gql, notifyError, paths } from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";
import { RoleNewPage } from "./new";
import { useTranslation } from "react-i18next";
import { PERMISSIONS } from "constants/enums/permissions";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";

export const API = {
  FETCH_ROLE: gql`
    query FETCH_ROLE($id: String!) {
      role(id: $id) {
        id
        title
        description
        permissions
        createdAt
        updatedAt
      }
    }
  `,
  FETCH_ROLE_OPTINS: gql`
    query FETCH_ROLE_OPTINS {
      roleOptions {
        roleOptions {
          label
          value
        }
        permissionOptions {
          label
          value
        }
      }
    }
  `,
  EDIT_ROLE: gql`
    mutation EDIT_ROLE(
      $id: String
      $title: String
      $permissions: [String!]
      $description: String
    ) {
      editRole(
        input: {
          id: $id
          title: $title
          permissions: $permissions
          description: $description
        }
      ) {
        role {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    enableReinitialize: true,
  }),
  withPreventLeaveDirtyForm(),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.roles.edit" }),
  withHooks((props: any, hooks: any) => {
    const { setInitialValues, values, currentUser, setValues, t } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useParams,
      useCallback,
    } = hooks;
    const { id } = useParams();
    const { loading, data, error, refetch } = useQuery(API.FETCH_ROLE, {
      variables: { id },
    });
    const { data: roleOptionData } = useQuery(API.FETCH_ROLE_OPTINS);
    const [editRole] = useMutation(API.EDIT_ROLE);

    const role = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.rolesPath().push();
        return null;
      }
      return data.role;
    }, [loading, data, error]);

    const title = t(".editRole");

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: paths.rolesPath(),
          label: t(".indexTitle"),
        },
        { path: null, label: title },
      ];
    }, [title, t]);

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      setInitialValues(role);
    }, [setInitialValues, role]);

    useHandleSubmit(
      async (values: any) => {
        try {
          await editRole({
            variables: values,
          });
          paths.rolesPath().push();
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {}
      },
      [editRole]
    );

    const handleCancel = useCallback(() => {
      paths.rolesPath().push();
    }, []);

    const enabledSubmitButton =
      values?.permissions?.length > 0 && values?.title;

    const { roleOptions, permissionOptions } = useMemo(() => {
      return (
        roleOptionData?.roleOptions ?? {
          roleOptions: [],
          permissionOptions: [],
        }
      );
    }, [roleOptionData]);

    const hasEditPermission = useMemo(() => {
      const permission = ["PERMISSION_MANAGEMENT_EDIT"];
      return permission.every((perm) =>
        currentUser?.role?.permissions.includes(perm)
      );
    }, [currentUser]);

    const isSuperAdminRole = useMemo(() => {
      return role?.title?.toLowerCase().replace(/\s/g, "") === "superadmin";
    }, [role]);

    const havePermission = useCallback(
      (permissions: any) => {
        return [permissions].every((perm: any) =>
          values?.permissions?.includes(perm)
        );
      },
      [values]
    );

    const disabledAdminRead = havePermission(PERMISSIONS.ADMIN_MANAGEMENT_EDIT);
    const disabledPermissionRead = havePermission(
      PERMISSIONS.PERMISSION_MANAGEMENT_EDIT
    );
    const disabledEnrollmentRead = havePermission(
      PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT
    );
    const disabledReportRead = havePermission(
      PERMISSIONS.REPORT_MANAGEMENT_EDIT
    );
    const disabledInsuranceRead = havePermission(
      PERMISSIONS.INSURANCE_MANAGEMENT_EDIT
    );
    const disabledSettingRead = havePermission(
      PERMISSIONS.SETTING_MANAGEMENT_EDIT
    );

    useEffect(() => {
      if (havePermission(PERMISSIONS.ADMIN_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.ADMIN_MANAGEMENT_READ))
          perms.push(PERMISSIONS.ADMIN_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.PERMISSION_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.PERMISSION_MANAGEMENT_READ))
          perms.push(PERMISSIONS.PERMISSION_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.ENROLLMENT_MANAGEMENT_READ))
          perms.push(PERMISSIONS.ENROLLMENT_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.REPORT_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.REPORT_MANAGEMENT_READ))
          perms.push(PERMISSIONS.REPORT_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.INSURANCE_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.INSURANCE_MANAGEMENT_READ))
          perms.push(PERMISSIONS.INSURANCE_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      if (havePermission(PERMISSIONS.SETTING_MANAGEMENT_EDIT)) {
        const perms = values?.permissions;
        if (!havePermission(PERMISSIONS.SETTING_MANAGEMENT_READ))
          perms.push(PERMISSIONS.SETTING_MANAGEMENT_READ);
        setValues({ ...values, permissions: perms });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.permissions]);

    return {
      enabledSubmitButton,
      breadcrumbs,
      title,
      roleOptions,
      permissionOptions,
      handleCancel,
      hasEditPermission,
      isSuperAdminRole,
      disabledAdminRead,
      disabledPermissionRead,
      disabledEnrollmentRead,
      disabledReportRead,
      disabledInsuranceRead,
      disabledSettingRead,
    };
  })
);

export default enhancer(RoleNewPage);
