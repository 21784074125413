import { gql } from "@apollo/client";
import { Box, Grid, Paper, Stepper } from "components";
import Loader from "components/Loader";
import { StepConfigs } from "components/common/Stepper";
import Typography from "components/common/Typography";
import { EnumMasterInsuranceSubState } from "constants/enums/master-insurance-sub-state";
import { compose, withHooks, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { useEffect } from "react";
import paths from "routes/paths";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { InsuranceRoute, handleNavigateInsuranceStep } from "./insuranceRoutes";
import { homePath } from "utils/helper";

const PageSectionContainer = styled(Grid)`
  margin-top: 24px;
  display: flex;
`;

interface InsuranceDetailProps {
  loading: boolean;
  title: string;
  breadcrumbs: BreadcrumbsProps;
  t: TFunction;
  step: number;
  maxStep: number;
  insuranceState: string;
  isPublished: boolean;
  stepConfigs: StepConfigs[];
}

const InsuranceDetailComponent = (props: InsuranceDetailProps) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    paper={false}
  >
    {props.loading ? (
      <Loader />
    ) : (
      <PageSectionContainer container>
        <Grid item xs={4}>
          <Paper px={4} py={6} mr={6}>
            <Typography variant="h4">
              {props.t(".step", { step: props.step + 1, of: 3 })}
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <Typography
                variant="body1"
                color={
                  props.isPublished
                    ? AppColor["Other/Success"]
                    : AppColor["Text/Dark Grey"]
                }
              >
                {props.insuranceState}
              </Typography>
            </div>

            <Stepper
              step={props.step}
              stepConfigs={props.stepConfigs}
              maxStep={props.maxStep}
            />
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Paper px={4} py={6}>
              <InsuranceRoute />
            </Paper>
          </Box>
        </Grid>
      </PageSectionContainer>
    )}
  </PageContent>
);

const STEP = {
  [EnumMasterInsuranceSubState.insurance_creating]: 0,
  [EnumMasterInsuranceSubState.insurance_package_creating]: 1,
  [EnumMasterInsuranceSubState.validating]: 2,
  [EnumMasterInsuranceSubState.completed]: 2,
};

const API = {
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        subState
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.insurance.detail" }),
  withHooks((props: any, hooks: any) => {
    const { useParams, useMemo, useQuery, useState, useLocation } = hooks;
    const { t } = props;
    const { id } = useParams();
    const { pathname } = useLocation();

    const { data: detail, loading: detailLoading, refetch } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id },
        fetchPolicy: "network-only",
      }
    );

    const masterInsurance = useMemo(() => detail?.masterInsuranceDetail, [
      detail,
    ]);

    const [step, setStep] = useState();
    const [maxStep, setMaxStep] = useState();

    const isLoading = useMemo(() => {
      return detailLoading;
    }, [detailLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      const found =
        STEP[detail?.masterInsuranceDetail.state as keyof typeof STEP];
      const isTemplate = found ? true : false;

      return {
        year,
        isTemplate,
      };
    }, [isLoading, detail]);

    const title = useMemo(() => t(".title", { year: config?.year }), [
      t,
      config,
    ]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".breadcrumbs.home") },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        { path: null, label: title },
      ];
    }, [t, title]);

    const currentState: EnumMasterInsuranceSubState = useMemo(() => {
      const state = pathname.split("/")[3] as keyof typeof STEP;
      return state;
    }, [pathname]);

    const stepConfigs = useMemo(
      (): StepConfigs[] => [
        {
          label: t(".insuranceDetailTitle"),
          onClick: () => {
            handleNavigateInsuranceStep(
              id,
              EnumMasterInsuranceSubState.insurance_creating
            );
          },
        },
        {
          label: t(".packageTitle"),
          onClick: () => {
            handleNavigateInsuranceStep(
              id,
              EnumMasterInsuranceSubState.insurance_package_creating
            );
          },
        },
        {
          label: t(".validateAndPublish"),
          onClick: () => {
            handleNavigateInsuranceStep(
              id,
              EnumMasterInsuranceSubState.validating
            );
          },
        },
      ],
      [t, id]
    );

    useEffect(() => {
      setStep(STEP[currentState]);
      refetch();
    }, [currentState, refetch]);

    useEffect(() => {
      const state = masterInsurance?.subState as keyof typeof STEP;
      setMaxStep(STEP[state]);
    }, [masterInsurance]);

    const insuranceState = useMemo(() => {
      return t(`.${masterInsurance?.state}`);
    }, [masterInsurance?.state, t]);
    return {
      loading: isLoading,
      title,
      breadcrumbs,
      step,
      maxStep,
      insuranceState,
      isPublished: masterInsurance?.state === "published",
      stepConfigs,
    };
  })
);

export const InsuranceDetailPage = enhancer(InsuranceDetailComponent);
