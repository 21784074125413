import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper as MuiStepper,
  withStyles,
  StepperProps,
} from "@material-ui/core";
import { compose, withHooks } from "enhancers";
import styled, { css } from "styled-components";
import { AppColor } from "theme/app-color";

interface OwnerState {
  completed?: boolean;
  active?: boolean;
  error?: boolean;
}

const CustomStepIcon = styled("div")<{ ownerState: OwnerState }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.ownerState.active || props.ownerState.completed)
      return AppColor["Secondary/Hover"];
    return AppColor["Secondary  Light/Background"];
  }};
`;

const StepIconComponent = (props: StepIconProps) => {
  const { active, completed, error } = props;
  return <CustomStepIcon ownerState={{ completed, active, error }} />;
};

const CustomConnector = withStyles({
  active: {
    "& $line": {
      backgroundColor: AppColor["Secondary/Hover"],
    },
  },
  completed: {
    "& $line": {
      backgroundColor: AppColor["Secondary/Hover"],
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: AppColor["Secondary  Light/Background"],
    borderRadius: 1,
    width: 4,
    fontWeight: 600,
  },
})(StepConnector);

const CustomStepper = styled(MuiStepper)`
  margin-top: 26px;
  padding: 0;
  background: none;
  .MuiStepConnector-root.MuiStepConnector-vertical {
    margin-left: 6px;
    padding: 0px;
    min-width: 4px;
  }
  .MuiStepConnector-lineVertical {
    border-left-width: 4px;
  }
`;

const CustomStepLabel = styled(StepLabel)<{ canClick: boolean }>`
  .MuiStepIcon-root {
    font-size: 16px;
    font-weight: 600;
  }
  .MuiStepLabel-label {
    font-weight: 600;
    ${(props) =>
      props.canClick
        ? css`
            &.MuiStepLabel-active {
              color: ${AppColor["Secondary/Hover"]};
              opacity: 1;
            }
            color: ${AppColor["Secondary/Hover"]};
            cursor: pointer;
            &:hover {
              opacity: 0.5;
              transition: 0.2s;
            }
          `
        : css`
            cursor: not-allowed;
          `}
  }
`;

export interface StepConfigs {
  onClick?: () => void;
  label: string; // Translated
}

export interface CustomStepperProps {
  step: number;
  stepConfigs: StepConfigs[];
  canClickStep: (step: number) => boolean;
}

const StepperComponent = (props: CustomStepperProps) => (
  <CustomStepper
    activeStep={props.step}
    orientation="vertical"
    connector={<CustomConnector />}
  >
    {props.stepConfigs.map((config, ind) => (
      <Step
        onClick={
          props.canClickStep(ind) && props.step !== ind
            ? config.onClick
            : undefined
        }
      >
        <CustomStepLabel
          canClick={props.canClickStep(ind)}
          StepIconComponent={StepIconComponent}
        >
          {config.label}
        </CustomStepLabel>
      </Step>
    ))}
  </CustomStepper>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useCallback, useEffect } = hooks;
    const { step, stepConfigs, maxStep } = props;

    const canClickStep = useCallback(
      (step: number) => {
        return step < maxStep + 1;
      },
      [maxStep]
    );

    return { step, stepConfigs, canClickStep };
  })
);

export const Stepper = enhancer(StepperComponent);
