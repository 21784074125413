import { Box, IconButton, Modal, Typography as ProjectTypo } from "components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { PackagePricingType } from "constants/enums/package-pricing-type";
import { compose, withHooks, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { formatDate, gql } from "utils/helper";

const CustomHtmlContainer = styled(Box)`
  .CustomHtml > div > p {
    margin: 0;
  }
`;

const Container = styled(Box)`
  display: flex;
  align-self: center;
  justify-content: center;

  .MuiIconButton-label > svg {
    width: 16px;
    height: 16px;
  }
`;

const Typography = styled(ProjectTypo)`
  margin-bottom: 8px !important;
`;

interface ViewInsuranceButtonProps {
  openViewInsuranceModal: () => void;
  insurancePlanDetail: any;
  isAllRemaining: boolean;
}

const ViewInsuranceButton = ({
  insurancePlanDetail,
  isAllRemaining,
  openViewInsuranceModal,
}: ViewInsuranceButtonProps) => (
  <Container>
    <IconButton
      color={AppColor["Text/Primary"]}
      disabled={isEmpty(insurancePlanDetail)}
      onClick={openViewInsuranceModal}
    >
      <MaterialIcon name="RemoveRedEyeOutlined" />
    </IconButton>
  </Container>
);

interface ViewInsurancePageProps {
  t: TFunction;
  insurancePlanDetail: any;
  isAllRemaining: boolean;
}

const ViewInsurancePage = (props: ViewInsurancePageProps) => (
  <Box display="flex">
    <Box display="flex" flexDirection="column" minWidth={220}>
      <Typography variant="h6" mb={4}>
        {props.t(".insuranceName")}
      </Typography>

      <Typography variant="h6" mb={4}>
        {props.t(".insuranceType")}
      </Typography>

      <Typography variant="h6" mb={4}>
        {props.t(".startDate")}
      </Typography>

      <Typography variant="h6" mb={4}>
        {props.t(".endDate")}
      </Typography>

      <Typography variant="h6" mb={4}>
        {props.t(".premium")}
      </Typography>

      <Typography variant="h6" mb={4}>
        {props.t(".remark")}
      </Typography>

      <Typography variant="h6" mb={4}>
        {props.t(".description")}
      </Typography>
    </Box>

    <Box display="flex" flexDirection="column">
      <Typography variant="body1" mb={4}>
        {props.insurancePlanDetail?.name || "-"}
      </Typography>

      <Typography variant="body1" mb={4}>
        {props.insurancePlanDetail?.insuranceType || "-"}
      </Typography>

      <Typography variant="body1" mb={4}>
        {props.insurancePlanDetail?.protectionStartDate || "-"}
      </Typography>

      <Typography variant="body1" mb={4}>
        {props.insurancePlanDetail?.protectionEndDate || "-"}
      </Typography>

      <Typography variant="body1" mb={4}>
        {props.isAllRemaining
          ? props.t(".allPoint")
          : props.insurancePlanDetail?.premium || "-"}
      </Typography>

      <Typography variant="body1" mb={4}>
        {props.insurancePlanDetail?.remark || "-"}
      </Typography>

      <CustomHtmlContainer mb={4}>
        <Typography className="CustomHtml" variant="body1" mb={4}>
          {props.insurancePlanDetail?.description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.insurancePlanDetail?.description,
              }}
            />
          ) : (
            "-"
          )}
        </Typography>
      </CustomHtmlContainer>
    </Box>
  </Box>
);

export const API = {
  FETCH_INSURANCE_PLAN: gql`
    query FETCH_INSURANCE_PLAN($id: String!) {
      insurancePlan(id: $id) {
        nameTh
        nameEn
        premium
        remarkTh
        remarkEn
        description
        protectionStartDate
        protectionEndDate
        description
        insuranceType {
          nameTh
          nameEn
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useLazyQuery, useDataTranslation, useCallback } = hooks;
    const { t, insurancePlanId, packagePricingType } = props;
    const [
      fetchInsurancePlan,
      { data: insurancePlanRes, client },
    ] = useLazyQuery(API.FETCH_INSURANCE_PLAN);
    const isAllRemaining = useMemo(
      () => packagePricingType === PackagePricingType.ALL_REMAINING,
      [packagePricingType]
    );
    const insurancePlanTranslated = useDataTranslation(insurancePlanRes);

    const insurancePlan = useMemo(
      () => insurancePlanTranslated?.insurancePlan,
      [insurancePlanTranslated]
    );

    const insurancePlanDetail = useMemo(() => {
      if (insurancePlan) {
        const protectionStartDate = formatDate(
          insurancePlan.protectionStartDate,
          "dd/MM/yyy"
        );
        const protectionEndDate = formatDate(
          insurancePlan.protectionEndDate,
          "dd/MM/yyy"
        );

        return {
          ...insurancePlan,
          insuranceType: insurancePlan.insuranceType.name,
          protectionStartDate: protectionStartDate,
          protectionEndDate: protectionEndDate,
        };
      }
      return;
    }, [insurancePlan]);

    const title = useMemo(() => insurancePlanDetail?.name, [
      insurancePlanDetail,
    ]);

    const openViewInsuranceModal = useCallback(async () => {
      if (insurancePlanDetail) {
        Modal.alert({
          title: insurancePlanDetail.name,
          children: (
            <ViewInsurancePage
              t={t}
              insurancePlanDetail={insurancePlanDetail}
              isAllRemaining={isAllRemaining}
            />
          ),
          okButtonLabel: t(".close"),
          onOk: async ({ close }: any) => {
            close();
          },
          okButtonColor: AppColor["Primary/Primary"],
        });
      }
    }, [insurancePlanDetail, t, isAllRemaining]);

    useEffect(() => {
      if (insurancePlanId) {
        fetchInsurancePlan({
          variables: {
            id: insurancePlanId,
          },
        });
      } else {
        fetchInsurancePlan();
      }
    }, [fetchInsurancePlan, insurancePlanId]);

    return {
      title,
      insurancePlanDetail,
      openViewInsuranceModal,
      isAllRemaining,
    };
  })
);

export default enhancer(ViewInsuranceButton);
