import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { useTranslation } from "react-i18next";
import { Box, ClickAwayListener } from ".";
import { getCurrentLang } from "common/i18next";
import { AppColor } from "theme/app-color";
import Typography from "./Typography";
import styled from "styled-components";
import { MenuItem } from "@material-ui/core";
import { ReactComponent as LangIcon } from "assets/icon/lang-icon.svg";
import { MaterialIcon } from "./MaterialIcon";

const CustomMenuItem = styled(MenuItem)`
  // height: 48px;
`;

const MuiIcon = styled(MaterialIcon)`
  font-size: 16px;
  color: ${AppColor["White / White"]};
  margin-left: 4px;
`;

const LanguagesPopMenu = (props: any) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    alignItems="center"
    onClick={props.toggle}
    style={{ cursor: "pointer" }}
    position="relative"
    marginRight="12px"
  >
    {props.expanded && (
      <ClickAwayListener onClickAway={props.close}>
        <Box
          style={{ backgroundColor: "white" }}
          position="absolute"
          width={90}
          zIndex={1}
          top={24}
        >
          {props.languageOptions.map((languageOption: any) => (
            <CustomMenuItem
              onClick={() => props.changeLang(languageOption.value)}
            >
              <Typography variant="body1" color="Text/Black">
                {languageOption.label}
              </Typography>
            </CustomMenuItem>
          ))}
        </Box>
      </ClickAwayListener>
    )}
    <LangIcon />
    <Box display="flex" flexDirection="column" overflow="hidden" ml={1}>
      <Typography variant="caption" color="White / White">
        {props.t(".lang")}
      </Typography>
    </Box>
    {props.expanded ? (
      <MuiIcon name="ArrowDropUp" />
    ) : (
      <MuiIcon name="ArrowDropDown" />
    )}
  </Box>
);

const enhancer = compose(
  withFormik({
    mapPropsToValues: (props: any) => ({
      language: getCurrentLang(),
    }),
  }),
  withTranslation({ prefix: "components.common.languagesDropdown" }),
  withHooks((props: any, hooks: any) => {
    const { position } = props;
    const { useMemo, useState, useCallback } = hooks;
    const [expanded, setExpanded] = useState(false);
    const { i18n } = useTranslation();

    const toggle = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const close = useCallback(() => {
      setExpanded(false);
    }, [setExpanded]);

    const languageOptions = useMemo(
      () => [
        { label: "ไทย", value: "th" },
        { label: "English", value: "en" },
      ],
      []
    );
    const changeLang = useCallback(
      (lang: string) => {
        if (i18n.language !== lang) {
          i18n.changeLanguage(lang);
        }
      },
      [i18n]
    );

    return {
      languageOptions,
      position,
      toggle,
      close,
      expanded,
      changeLang,
    };
  })
);

export default enhancer(LanguagesPopMenu);
