import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Tab, Table, Tabs, Typography } from "components";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import insuranceStore from "stores/insuranceStore";
import styled from "styled-components";
import { gql } from "utils/helper";
import ViewInsuranceButton from "../insurancePackages/ViewInsuranceButtonComponent";
import { insuranceStepPath } from "../insuranceRoutes";
import { AppColor } from "theme/app-color";

const TableContainer = styled(Box)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
`;

const CustomButton = styled(Button)`
  min-width: 110px;
  height: 34px;
  margin: 0 16px 4px 0;
  padding: 0 16px 0 16px;
  text-align: center;
`;

export interface InsuranceTypeInterface {
  id: string;
  name: string;
  symbol: string;
}

export const InsuranceTableComponent = (props: any) => (
  <Box display="flex" flexDirection="column">
    <Tabs value={props.tabState} onChange={props.changeTabState}>
      <Tab
        label={props.t(".tab1")}
        style={{
          color:
            props.tabState === 0
              ? AppColor["Primary/Primary"]
              : AppColor["Text/Secondary"],
        }}
      />
      <Tab
        label={props.t(".tab2")}
        style={{
          color:
            props.tabState === 1
              ? AppColor["Primary/Primary"]
              : AppColor["Text/Secondary"],
        }}
      />
    </Tabs>
    <Box>
      {props.tabState === 0 && (
        <Box>
          {!isEmpty(props.insurancesTableData) ? (
            <TableContainer>
              <Box mb={4}>
                {props.insuranceType.map((type: InsuranceTypeInterface) => (
                  <CustomButton
                    variant={
                      type.id === props.insuranceFilter.id
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() =>
                      props.setInsuranceFilter({
                        id: type.id,
                        name: type.name,
                      })
                    }
                  >
                    <Typography variant="Body/16">{type.name}</Typography>
                  </CustomButton>
                ))}
              </Box>
              <Typography my={2} variant="body2">
                {props.t(".insuranceTableHeader", {
                  name: props.insuranceFilter.name,
                })}
              </Typography>
              <Table
                columns={props.columns}
                rows={props.insurancesTableData || []}
                density="compact"
                autoHeight
                style={{ minHeight: "40px" }}
              />
            </TableContainer>
          ) : (
            <TableContainer>
              <Typography
                my={2}
                variant="body1"
                textAlign="center"
                color="Text/Dark Grey"
              >
                {props.t(".notFoundData")}
              </Typography>
            </TableContainer>
          )}
        </Box>
      )}
      {props.tabState === 1 && (
        <Box>
          {!isEmpty(props.insurancePackagesTableData) ? (
            <TableContainer>
              <Box mb={4}>
                {props.insurancePackages.map((insurance: any) => (
                  <CustomButton
                    variant={
                      insurance.id === props.insurancePackageFilter.id
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() =>
                      props.setInsurancePackageFilter({
                        id: insurance.id,
                        name: insurance.name,
                      })
                    }
                  >
                    <Typography variant="Body/16">{insurance.name}</Typography>
                  </CustomButton>
                ))}
              </Box>
              <Typography my={2} variant="body2">
                {`Package ${props.insurancePackageFilter.name}`}
              </Typography>
              <Table
                columns={props.columnsPackage}
                rows={props.insurancePackagesTableData || []}
                density="compact"
                autoHeight
                style={{ minHeight: "40px" }}
              />
            </TableContainer>
          ) : (
            <TableContainer>
              <Typography
                my={2}
                variant="body1"
                textAlign="center"
                color="Text/Dark Grey"
              >
                {props.t(".notFoundPackage")}
              </Typography>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  </Box>
);

export const API = {
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        subState
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          packagePricingType
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.insurance.InsurancePreview" }),
  withHooks((props: any, hooks: any) => {
    const {
      useState,
      useLocation,
      useParams,
      useMemo,
      useQuery,
      useCallback,
      useDataTranslation,
    } = hooks;
    const { t } = props;

    const [tabState, setTabState] = useState(0);
    const [insuranceFilter, setInsuranceFilter] = useState({
      id: "",
      name: "",
    });
    const [insurancePackageFilter, setInsurancePackageFilter] = useState({
      id: "",
      name: "",
    });
    const location = useLocation();
    const { hash: hashTag } = location || {};
    const { id } = useParams();

    const { data: types } = useQuery(API.FETCH_INSURANCE_TYPES);
    const { data: masterInsurancePlans } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id },
      }
    );

    const masterInsurances = useDataTranslation(
      masterInsurancePlans?.masterInsuranceDetail
    );
    const insuranceTypesTranslated = useDataTranslation(types);

    const insurances = useMemo(() => {
      if (masterInsurances) {
        const insurancePlans: any = {};
        masterInsurances?.insurances.forEach((insurance: any) => {
          insurancePlans[insurance.id] = insurance;
        });
        return insurancePlans;
      }
      return undefined;
    }, [masterInsurances]);

    const insuranceType = useMemo(() => {
      if (isEmpty(insuranceTypesTranslated?.insuranceTypes)) {
        return [];
      }
      setInsuranceFilter({
        id: insuranceTypesTranslated.insuranceTypes[0].id,
        name: insuranceTypesTranslated.insuranceTypes[0].name,
      });

      const masterInsurancesTypeid = masterInsurances?.insurances.map(
        (insurance: any) => insurance.insuranceTypeId
      );

      return insuranceTypesTranslated.insuranceTypes.filter(
        (insuranceType: any) =>
          masterInsurancesTypeid?.includes(insuranceType.id)
      );
    }, [insuranceTypesTranslated, masterInsurances]);

    const insurancePackages = useMemo(() => {
      const packages = masterInsurances?.insurancePackages;

      if (isEmpty(packages) || isEmpty(insurances)) {
        return [];
      }

      const result = packages.map((packageItem: any) => {
        const insuranceDetail = packageItem.insurancesInsurancePackages.map(
          (relation: any) => insurances[relation.insurancePlanId]
        );
        return {
          ...packageItem,
          insurances: insuranceDetail,
        };
      });
      setInsurancePackageFilter({
        id: result[0].id,
        name: result[0].name,
      });
      return result;
    }, [masterInsurances, insurances]);

    useEffect(() => {
      switch (hashTag) {
        case "#insuranceType":
          return setTabState(0);
        case "#insurancePackage":
          return setTabState(1);
        default:
          return setTabState(0);
      }
    }, [hashTag]);

    const changeTabState = useCallback(
      (e: any, value: any) => {
        switch (value) {
          case 0:
            return insuranceStepPath
              .insuranceValidation(id)
              .push("#insuranceType");
          case 1:
            return insuranceStepPath
              .insuranceValidation(id)
              .push("#insurancePackage");
          default:
            return insuranceStepPath
              .insuranceValidation(id)
              .push("#insuranceType");
        }
      },
      [id]
    );

    const columns = useMemo(
      () => [
        {
          width: 280,
          field: "name",
          headerName: t(".insuranceName"),
        },
        {
          width: 280,
          field: "remark",
          headerName: t(".insuranceRemark"),
        },
        {
          width: 90,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          renderCell: (row: any) => (
            <ViewInsuranceButton insurancePlanId={row.id} />
          ),
        },
      ],
      [t]
    );
    const columnsPackage = useMemo(
      () => [
        {
          width: 280,
          field: "name",
          headerName: t(".insuranceName"),
        },
        {
          width: 280,
          field: "packagePricingType",
          headerName: t(".packagePricingType"),
          renderCell: (row: any) => {
            return t(`.${row.row.packagePricingType}`);
          },
        },
        {
          width: 280,
          field: "remark",
          headerName: t(".insuranceRemark"),
        },
        {
          width: 90,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          renderCell: (row: any) => (
            <ViewInsuranceButton
              insurancePlanId={row.id}
              packagePricingType={row.row.packagePricingType}
            />
          ),
        },
      ],
      [t]
    );

    const insurancesTableData = useMemo(() => {
      if (isEmpty(insurances)) {
        return [];
      }

      const list = Object.keys(insurances).map((key) => insurances[key]);

      return list.filter(
        (insurances) => insurances.insuranceTypeId === insuranceFilter.id
      );
    }, [insurances, insuranceFilter]);

    const insurancePackagesTableData = useMemo(() => {
      if (isEmpty(insurancePackages)) {
        return [];
      }
      const insurancePackage = insurancePackages.find(
        (packageItem: any) => packageItem.id === insurancePackageFilter.id
      );
      const result =
        insurancePackage?.insurances.map((insurance: any) => {
          return {
            ...insurance,
            packagePricingType: insurancePackage.packagePricingType,
          };
        }) || [];

      const tableData: any[] = [];

      if (result) {
        result.map((data: any) => {
          tableData.push(data);
        });
      }

      return tableData;
    }, [insurancePackages, insurancePackageFilter]);

    return {
      tabState,
      changeTabState,
      columns,
      insuranceType,
      setInsuranceFilter,
      setInsurancePackageFilter,
      insurancesTableData,
      insuranceFilter,
      insurancePackages,
      insurancePackageFilter,
      insurancePackagesTableData,
      columnsPackage,
      t,
    };
  })
);
export default enhancer(InsuranceTableComponent);
