import { Modal } from "components";
import withHooks from "./withHooks";
import { AppColor } from "theme/app-color";
import { history } from "components/common/BrowserRouter";

const withPreventLeaveDirtyForm = (outerProps) =>
  withHooks((props, hooks) => {
    const { dirty, preventLeaveDirtyForm } = props;
    const { useEffect, useRef, useCallback, useTranslation } = hooks;
    const { t } = useTranslation();

    const preventDirtyInfo = useRef(
      {
        dirty,
        enabled: preventLeaveDirtyForm || true,
        t: t,
      },
      []
    );

    preventDirtyInfo.current = {
      ...preventDirtyInfo.current,
      dirty,
    };

    const enablePreventLeaveDirtyForm = useCallback(() => {
      preventDirtyInfo.current = {
        ...preventDirtyInfo.current,
        enabled: true,
      };
    }, []);

    const disablePreventLeaveDirtyForm = useCallback(() => {
      preventDirtyInfo.current = {
        ...preventDirtyInfo.current,
        enabled: false,
      };
    }, []);

    const preventDirtyForm = useCallback((e) => {
      const { enabled, dirty } = preventDirtyInfo.current;
      if (enabled && dirty) {
        e.preventDefault();
        return (e.returnValue = "");
      }
    }, []);

    useEffect(() => {
      window.addEventListener("beforeunload", preventDirtyForm);
      return () => {
        window.removeEventListener("beforeunload", preventDirtyForm);
      };
    }, [preventDirtyForm]);

    useEffect(() => {
      const unblock = history.block((location) => {
        const { enabled, dirty, t } = preventDirtyInfo.current;
        if (enabled && dirty) {
          Modal.confirm({
            title: t("client.enhancers.withPreventLeaveDirtyForm.cancelEdit"),
            children: t(
              "client.enhancers.withPreventLeaveDirtyForm.cancelConfirm"
            ),
            cancelButtonLabel: t(
              "client.enhancers.withPreventLeaveDirtyForm.close"
            ),
            okButtonLabel: t(
              "client.enhancers.withPreventLeaveDirtyForm.canceledEdit"
            ),
            onOk: async ({ close }) => {
              if (outerProps?.onOk) outerProps.onOk();
              await close();
              unblock();
              history.push(location);
            },
            okButtonVariant: "outlined",
          });
          return false;
        }
      });

      return () => {
        unblock();
      };
    }, []);

    return {
      ...props,
      enablePreventLeaveDirtyForm,
      disablePreventLeaveDirtyForm,
    };
  });

export default withPreventLeaveDirtyForm;
