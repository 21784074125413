import { removeLoggingAd, removeRefreshToken, removeToken } from "api";
import server from "common/api";
import { clone, mapValues } from "lodash";
import { BaseStore } from "./index";
// import { Modal, ExcelGenerator } from "components";

export const MAIN_MODAL_INITIAL_VALUES = {
  isShow: false,
  okButtonLabel: "เลือก",
  okButtonVariant: "outlined",
  cancelButtonLabel: "ยกเลิก",
  fullWidth: true,
  maxWidth: "sm",
};
const ALERT_MODAL_INITIAL_VALUES = {
  isShow: false,
  title: "Warning",
  okButtonLabel: "ตกลง",
  fullWidth: true,
  maxWidth: "sm",
};
const CONFIRM_MODAL_INITIAL_VALUES = {
  isShow: false,
  title: "Are you sure?",
  okButtonLabel: "ตกลง",
  cancelButtonLabel: "ยกเลิก",
  fullWidth: true,
  maxWidth: "sm",
};
const IMAGE_LIGHTBOX_INITIAL_VALUES = {
  isShow: false,
  image: null,
  images: [],
  title: "",
};

const makeModalAction = (onAction, onClose, setState) => async (modal) => {
  if (onAction) {
    setState({ disabled: true });
    try {
      await onAction({ ...modal, close: onClose });
    } catch (e) {}
    setState({ disabled: false });
  } else {
    onClose();
  }
};

class AppStore extends BaseStore {
  constructor() {
    super("appStore", {
      initialized: false,
      hasFirstAdmin: null,
      mainModal: clone(MAIN_MODAL_INITIAL_VALUES),
      alertModal: clone(ALERT_MODAL_INITIAL_VALUES),
      confirmModal: clone(CONFIRM_MODAL_INITIAL_VALUES),
      imageLightbox: clone(IMAGE_LIGHTBOX_INITIAL_VALUES),
      notification: null,
      excelGenerator: {},
      authenticationToken: null,
      language: "th",
      currentUser: null,
      configSidebarRefetch: () => {},
    });

    // ExcelGenerator.generate = this.generateExcel;
  }
  setHasFirstAdmin = (hasFirstAdmin) => {
    this.setState({ hasFirstAdmin });
  };

  setLanguage = (language) => {
    this.setState({ language });
  };

  setCurrentUser = (currentUser) => {
    this.setState({ currentUser });
  };

  generateExcel = async (params) => {
    this.setState({
      excelGenerator: {
        fileName: params.fileName,
        configs: params.configs.map((config) => ({
          sheetName: config.sheetName,
          columns: config.columns,
          data: config.data,
        })),
        triggeredGenerator: true,
        onGenerated: () => {
          this.setState({ excelGenerator: {} });
        },
      },
    });
  };

  getInfo = async () => {
    const response = await server.get("/info");
    const { info } = response.data.data;
    this.setState({
      hasFirstAdmin: info.hasFirstAdmin,
    });
  };

  openMainModal = (config = {}) => {
    const set = (newState) => this.setState({ mainModal: newState });
    set({
      ...mapValues(this.state.mainModal, () => undefined),
      ...MAIN_MODAL_INITIAL_VALUES,
      ...config,
      isShow: true,
      onOk: makeModalAction(config.onOk, this.closeMainModal, set),
      onCancel: makeModalAction(config.onCancel, this.closeMainModal, set),
      onClose: makeModalAction(config.onClose, this.closeMainModal, set),
    });
  };

  closeMainModal = () => {
    this.setState({ mainModal: { isShow: false } });
  };

  openAlertModal = (config = {}) => {
    const set = (newState) => this.setState({ alertModal: newState });
    set({
      ...mapValues(this.state.alertModal, () => undefined),
      ...ALERT_MODAL_INITIAL_VALUES,
      ...config,
      isShow: true,
      onOk: makeModalAction(config.onOk, this.closeAlertModal, set),
      onClose: makeModalAction(config.onClose, this.closeAlertModal, set),
    });
  };

  closeAlertModal = () => {
    this.setState({ alertModal: { isShow: false } });
  };

  openConfirmModal = (config = {}) => {
    const set = (newState) => this.setState({ confirmModal: newState });
    set({
      ...mapValues(this.state.confirmModal, () => undefined),
      ...CONFIRM_MODAL_INITIAL_VALUES,
      ...config,
      isShow: true,
      onOk: makeModalAction(config.onOk, this.closeConfirmModal, set),
      onCancel: makeModalAction(config.onCancel, this.closeConfirmModal, set),
      onClose: makeModalAction(config.onClose, this.closeConfirmModal, set),
    });
  };

  closeConfirmModal = () => {
    this.setState({ confirmModal: { isShow: false } });
  };

  openImageLightbox = (config = {}) => {
    this.setState({
      imageLightbox: {
        ...config,
        isShow: true,
        onClose: this.closeImageLightbox,
      },
    });
  };

  closeImageLightbox = () => {
    this.setState({ imageLightbox: { ...IMAGE_LIGHTBOX_INITIAL_VALUES } });
  };

  notify = (message, options) => {
    this.setState({
      notification: {
        message,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        ...options,
      },
    });
  };

  notifyError = (message = "Error!", options) => {
    this.notify(message, { ...options, variant: "error" });
  };

  notifySuccess = (message = "Success!", options) => {
    this.notify(message, { ...options, variant: "success" });
  };

  logout = () => {
    removeToken();
    removeRefreshToken();
    removeLoggingAd();
    window.location.reload();
  };
}

export default new AppStore();
