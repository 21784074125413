import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { Yup, gql } from "utils/helper";

import { useMsal } from "@azure/msal-react";
import { startLoggingAd } from "api";
import { Box, Button, Field, Form, TextField, Typography } from "components";
import { TFunction } from "i18next";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { VerifyOtp } from "./VerifyOtp";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

interface LoginProps {
  currentEmail?: string;
  sendOtpPassed: boolean;
  handleClickChangeEmail: () => void;
  handleSendRequestOtp: (email: string) => void;
  hasFirstAdmin: boolean;
  refCode?: string;
  otpRetryTimeInSec?: number;
  otpExpireInSec?: number;
  token: string;
  isRequestOtpDisabled: boolean;
  t: TFunction;
  handleClickLoginWithAzureAd: () => void;
  handleLogoutAzureAd: () => void;
  isShowLogout: boolean;
}

const LoginPageComponent = (props: LoginProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minWidth={357}
    width="100%"
    height={900}
    bgcolor={AppColor["Background/Light Grey"]}
  >
    {/* <FlexIcon /> */}
    <Box>
      <Typography variant="Header/48" color={AppColor["Primary/Primary"]}>
        {props.t(".title")}
      </Typography>
    </Box>
    {props.sendOtpPassed ? (
      <VerifyOtp
        currentEmail={props.currentEmail}
        onClickChangeEmail={props.handleClickChangeEmail}
        onClickResendRequestOtp={props.handleSendRequestOtp}
        refCode={props.refCode}
        otpRetryTimeInSec={props.otpRetryTimeInSec}
        otpExpireInSec={props.otpExpireInSec}
        token={props.token}
      />
    ) : (
      <Box
        width={520}
        bgcolor={AppColor["White / White"]}
        borderRadius="8px"
        marginTop="48px"
      >
        <Container>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "16px",
              marginTop: "16px",
              width: "100%",
            }}
          >
            {props.isShowLogout ? (
              <Button
                onClick={props.handleLogoutAzureAd}
                width="100%"
                mb="16px"
              >
                {props.t(".logoutAzureAd")}
              </Button>
            ) : (
              <Button
                onClick={props.handleClickLoginWithAzureAd}
                width="100%"
                mb="16px"
              >
                {props.t(".signInWithAzureAd")}
              </Button>
            )}

            <Typography variant="Helper/12">{props.t(".or")}</Typography>
          </Box>
          <div style={{ marginBottom: 24 }}>
            <Typography variant="h4">
              {props.hasFirstAdmin ? props.t(".title1") : props.t(".title2")}
            </Typography>
          </div>
          <Form style={{ width: "100%" }}>
            <Field
              fast
              component={TextField}
              name="email"
              label={props.t(".emailField")}
              fullWidth
            />
            <div style={{ marginTop: 40, width: "100%" }}>
              <Button
                disabled={props.isRequestOtpDisabled}
                type="submit"
                width="100%"
              >
                {props.t(".getOtp")}
              </Button>
            </div>
          </Form>
        </Container>
      </Box>
    )}
  </Box>
);

const API = {
  REQUEST_OTP: gql`
    mutation REQUEST_OTP($email: String!) {
      requestBackofficeUserOtp(input: { email: $email }) {
        status
        refCode
        otpRetryTimeInSec
        otpExpireInSec
        token
      }
    }
  `,
  SIGN_IN_WITH_AZURE_AD: gql`
    mutation SIGN_IN_WITH_AZURE_AD($email: String!) {
      signInWithAzureAd(email: $email) {
        currentUser {
          id
          email
          authenticationToken
          role {
            permissions
          }
        }
        accessToken
        refreshToken
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    hasFirstAdmin: stores.appStore.hasFirstAdmin,
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .email(".invalidEmailSyntax"),
    }),
  }),
  withTranslation({ prefix: "pages.main.authentication.LoginPage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useState,
      useHandleSubmit,
      useCallback,
      useMutation,
      useMemo,
    } = hooks;
    const { values, hasFirstAdmin, currentUser, t } = props;
    const { instance, accounts, inProgress } = useMsal();

    const [requestOtp] = useMutation(API.REQUEST_OTP, {
      onCompleted: (data: any) => setOtpModel(data.requestBackofficeUserOtp),
    });
    const [sendOtpPassed, setSendOtpPassed] = useState(false);
    const [otpModel, setOtpModel] = useState(undefined);
    const isRequestOtpDisabled = useMemo(() => !values.email, [values]);
    const handleSendRequestOtp = useCallback(
      async (email: string) => {
        await requestOtp({
          variables: { email: email.trim() },
        });
      },
      [requestOtp]
    );

    useHandleSubmit(async (values: { email: string }) => {
      await handleSendRequestOtp(values.email);
      setSendOtpPassed(true);
    }, []);

    const handleClickChangeEmail = useCallback(() => {
      setSendOtpPassed(false);
    }, []);

    const activeAccount = useMemo(() => instance.getActiveAccount(), [
      instance,
    ]);

    const handleClickLoginWithAzureAd = useCallback(async () => {
      startLoggingAd();
      await instance.loginRedirect();
    }, [instance]);

    const handleLogoutAzureAd = useCallback(async () => {
      await instance.logout({
        postLogoutRedirectUri: "/",
        account: activeAccount,
        onRedirectNavigate: (url) => {
          instance.setActiveAccount(null);
          return true;
        },
      });
    }, [activeAccount, instance]);

    // const isShowLogout = useMemo(() => {
    //   const account = accounts[0];
    //   return !currentUser && account;
    // }, [accounts, currentUser]);
    const isShowLogout = false;

    return {
      sendOtpPassed,
      currentEmail: values.email.trim(),
      handleClickChangeEmail,
      handleSendRequestOtp,
      isRequestOtpDisabled,
      hasFirstAdmin,
      handleClickLoginWithAzureAd,
      handleLogoutAzureAd,
      isShowLogout,
      ...otpModel,
    };
  })
);

export const LoginPage = enhancer(LoginPageComponent);
