import React from "react";
import { compose, withHooks, withTranslation } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Button,
  BrowseButton,
  FileUpload,
} from "components";
import { get, range } from "lodash";
import { TFunction } from "i18next";

interface FileUploadListProps {
  t: TFunction;
  title: string;
  filename: string;
  addButtonLabel: string;
  name: string;
  amount: number;
  simple: boolean;
  download: () => void;
  addDocument: any;
  removeDocument: (idx: number) => void;
}

const FileUploadList = (props: FileUploadListProps) => (
  <Box {...props}>
    {props.simple ? (
      <>
        <BrowseButton startIcon="upload" onBrowse={props.addDocument} mt={6}>
          <Typography color="Text/Black">{props.addButtonLabel}</Typography>
        </BrowseButton>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={6}>
              <Field
                component={FileUpload}
                name={`${props.name}[${index}]`}
                width={512}
                pl={4}
                simple
              />
              <Button
                startIcon="bigTrash"
                onClick={() => {
                  props.removeDocument(index);
                }}
                ml={2}
              />
            </Box>
          );
        })}
      </>
    ) : (
      <>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" flex={1}>
            {props.title}
          </Typography>
          <Typography variant="body1">
            {props.t(".total", { amount: props.amount })}
          </Typography>
        </Box>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={6}>
              <Field
                component={FileUpload}
                name={`${props.name}[${index}]`}
                width={512}
                pl={4}
              />
              <Button
                startIcon="bigTrash"
                onClick={() => {
                  props.removeDocument(index);
                }}
                ml={2}
              />
            </Box>
          );
        })}
        <BrowseButton startIcon="upload" onBrowse={props.addDocument} mt={6}>
          <Typography color="Text/Black">{props.addButtonLabel}</Typography>
        </BrowseButton>
      </>
    )}
  </Box>
);

const enhancer = compose(
  withTranslation({ prefix: "components.advance.FileUploadList" }),
  withHooks((props: any, hooks: any) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
    } = props;
    const { useCallback } = hooks;
    const amount = get(form.values, name)?.length ?? 0;

    const addDocument = useCallback(
      (file: any) => {
        insert(amount, file);
      },
      [insert, amount]
    );

    const removeDocument = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove]
    );

    return {
      name,
      amount,
      addDocument,
      removeDocument,
      title,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
    };
  })
);

export default enhancer(FileUploadList);
