import { Typography } from "components";
import { compose, withHooks, withTranslation } from "enhancers";
// TODO: Change T Props

const T = ({ t, children, ...rest }: any) => (
  <Typography {...rest}>{t(children)}</Typography>
);

const enhancer = compose(
  withTranslation(),
  withHooks((props: any, hooks: any) => {
    return { ...props };
  })
);

export default enhancer(T);
