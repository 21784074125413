import { compose, withHooks, withTranslation } from "enhancers";
import { Box, BrowseButton, Field, ImageLightbox } from "components";
import ImageUpload from "./ImageUpload";
import { get, range, map } from "lodash";
import Authorize from "components/Authorize";

import { ReactComponent as AddImageIcon } from "assets/icon/add-icon.svg";
import { AppColor } from "theme/app-color";
import { PERMISSIONS } from "constants/enums/permissions";
import { BoxProps } from "@material-ui/core";
import { TFunction } from "i18next";

interface LogoUploadProps extends BoxProps {
  t: TFunction;
  noStartIcon: any;
  amount: number;
  name: string;
  hasEditPermission: string;
  canAddMore: boolean;
  addImage: any;
}

const LogoUpload = (props: LogoUploadProps) => (
  <Box {...props}>
    <Box
      display="flex"
      style={{
        overflow: "hidden",
        overflowX: "auto", // added scroll
      }}
    >
      {range(props.amount).map((index) => {
        return (
          <Box key={index} mb={2} mr={6}>
            {/* <Box onClick={() => props.openImageLightbox(index)}> */}
            <Field component={ImageUpload} name={`${props.name}[${index}]`} />
            {/* </Box> */}
          </Box>
        );
      })}
    </Box>
    <Box display="flex" flex={1}>
      <Box display="flex" flexDirection="column">
        <Authorize permissions={[PERMISSIONS.SETTING_MANAGEMENT_EDIT]}>
          <BrowseButton
            variant="outlined"
            startIcon={props.noStartIcon ? "" : <AddImageIcon />}
            onBrowse={props.addImage}
            accept="image/*"
            disabled={!props.hasEditPermission}
          >
            {props.canAddMore ? props.t(".addImage") : props.t(".editImage")}
          </BrowseButton>
        </Authorize>
      </Box>
    </Box>
  </Box>
);

const enhancer = compose(
  withTranslation({ prefix: "pages.main.settings.index" }),
  withHooks((props: any, hooks: any) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      addButtonLabel,
      mt,
      label,
      onRemoveImage,
      hasEditPermission,
      noStartIcon,
    } = props;
    const { useCallback } = hooks;
    const values = get(form.values, name);
    const amount = values?.length ?? 0;

    const canAddMore = amount < 1;

    const removeImage = useCallback(
      (index: number) => {
        remove(index);
        if (onRemoveImage) {
          onRemoveImage(index, props);
        }
      },
      [remove, onRemoveImage, props]
    );

    const addImage = useCallback(
      (file: any) => {
        if (canAddMore) {
          insert(amount, file);
        } else {
          removeImage();
          insert(amount, file);
        }
      },
      [insert, amount, canAddMore, removeImage]
    );

    const openImageLightbox = useCallback(
      (index: number) => {
        const images = map(values, (file, index) => {
          return {
            url: file instanceof File ? URL.createObjectURL(file) : null,
            title: `รูปที่ ${index + 1}`,
          };
        });
        ImageLightbox.open({
          images,
          startIndex: index,
        });
      },
      [values]
    );

    return {
      name,
      amount,
      addImage,
      removeImage,
      title,
      addButtonLabel,
      mt,
      label,
      canAddMore,
      openImageLightbox,
      hasEditPermission,
      noStartIcon,
    };
  })
);

export default enhancer(LogoUpload);
