import { gql } from "@apollo/client";
import { BreadcrumbProps } from "antd";
import {
  Button,
  CKEditor,
  Field,
  Form,
  Grid,
  TextField,
  Typography,
} from "components";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { IInsuranceDetail } from "constants/interfaces/insuranceDetail";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";

import { TFunction } from "i18next";
import { PageContent } from "layouts";
import paths from "routes/paths";
import insuranceStore from "stores/insuranceStore";
import { AppColor } from "theme/app-color";
import { Yup, homePath, notifySuccess } from "utils/helper";
import { v4 as uuidv4 } from "uuid";
import { insuranceStepPath } from "./insuranceRoutes";

interface InsuranceFormProps {
  t: TFunction;
  title: string;
  breadcrumbs: BreadcrumbProps;
  values: any;
  handleClickCancel: () => void;
  viewOnly: boolean;
}

const InsuranceFormComponent = (props: InsuranceFormProps) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Typography variant="h4" color={AppColor["Text/Black"]} mb={6}>
      {props.t(".info")}
    </Typography>
    <Form style={{ width: "100%" }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Field
            type="text"
            label={props.t(".nameTh")}
            fullWidth
            component={TextField}
            name="nameTh"
            placeholder=""
            required
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            type="text"
            label={props.t(".nameEn")}
            fullWidth
            component={TextField}
            name="nameEn"
            placeholder=""
            required
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            label={props.t(".premium")}
            fullWidth
            component={TextField}
            name="premium"
            placeholder=""
            required
            type="number"
            thousandSeparator
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            type="text"
            label={props.t(".remarkTh")}
            fullWidth
            component={TextField}
            name="remarkTh"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            type="text"
            label={props.t(".remarkEn")}
            fullWidth
            component={TextField}
            name="remarkEn"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            fullWidth
            component={CKEditor}
            name="description"
            placeholder=""
            disabled={props.viewOnly}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "24px" }}>
          <Button
            onClick={props.handleClickCancel}
            style={{ marginRight: "16px" }}
            variant="outlined"
          >
            {props.t(".cancel")}
          </Button>
          {!props.viewOnly && <Button type="submit">{props.t(".save")}</Button>}
        </Grid>
      </Grid>
    </Form>
  </PageContent>
);

const API = {
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => {
    return {
      currentUser: stores.appStore.currentUser,

      insurances: stores.insuranceStore.insurances,
      insuranceSubState: stores.insuranceStore.insuranceSubState,
    };
  }),
  withFormik({
    mapPropsToValues: (props: any) => {
      const { id, insuranceId } = props.match.params;
      let initialValues = {
        nameTh: null,
        nameEn: null,
        remarkTh: null,
        remarkEn: null,
        premium: null,
        description: "",
      };
      if (insuranceId) {
        const initFormStore = props.insurances
          ? props.insurances[insuranceId]
          : null;
        if (initFormStore) {
          initialValues = {
            ...initFormStore,
          };
        } else {
          insuranceStepPath.insuranceDetail(id).push();
        }
      }
      return initialValues;
    },
    validationSchema: Yup.object().shape({
      premium: Yup.number().min(0, ".GteZero").typeError(".typeNumber"),
    }),
  }),
  withPreventLeaveDirtyForm(),
  withTranslation({ prefix: "pages.main.insurance.InsuranceForm" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useParams,
      useHandleSubmit,
      useQuery,
      useLocation,
      useEffect,
      useDataTranslation,
    } = hooks;

    const {
      values,
      initialValues,
      insurances,
      setInitialValues,
      insuranceSubState,
      currentUser,
      t,
    } = props;

    const hasEditPermission = useMemo(
      () =>
        currentUser?.role?.permissions.includes(
          PERMISSIONS.INSURANCE_MANAGEMENT_EDIT
        ),
      [currentUser]
    );

    const viewOnly = useMemo(() => {
      if (insuranceSubState === EnumMasterInsuranceState.published) return true;
      if (!hasEditPermission) return true;
      return false;
    }, [insuranceSubState, hasEditPermission]);

    const { id: detailId, insuranceId } = useParams();
    const { search } = useLocation();
    const insuranceType = useMemo(
      () => new URLSearchParams(search).get("insuranceType"),
      [search]
    );

    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: detailId },
      }
    );
    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const typesTranslated = useDataTranslation(types);
    const insurancesTranslated = useDataTranslation(insurances);

    const insuranceTypes = useMemo(
      () => typesTranslated?.insuranceTypes || [],
      [typesTranslated]
    );

    const insuranceTypeDetail = useMemo(() => {
      if (insuranceType) {
        const found = insuranceTypes.find(
          (type: any) => type.symbol === insuranceType
        );
        return found;
      } else if (insuranceId && insurancesTranslated) {
        const insurance = insurancesTranslated[insuranceId];

        const found = insuranceTypes.find(
          (type: any) => type.id === insurance.insuranceTypeId
        );
        return found;
      } else {
        paths.insuranceDetailPath(detailId).push();
      }
    }, [
      insuranceTypes,
      insuranceType,
      insurancesTranslated,
      insuranceId,
      detailId,
    ]);

    const title = useMemo(() => {
      if (insuranceType) return insuranceTypeDetail?.name;
      if (insuranceId && insurancesTranslated)
        return insurancesTranslated[insuranceId].name;
    }, [insuranceType, insuranceTypeDetail, insuranceId, insurancesTranslated]);

    const breadcrumbTitle = useMemo(() => {
      return title;
    }, [title]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: paths.insuranceDetailPath(detailId),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: breadcrumbTitle },
      ];
    }, [t, detailId, config, breadcrumbTitle]);

    useHandleSubmit(
      (values: IInsuranceDetail) => {
        const id = insuranceId ? insuranceId : uuidv4();
        insuranceStore.saveInsuranceTemp(id, {
          ...values,
          insuranceTypeId: insuranceTypeDetail.id,
          id,
        });
        insuranceStepPath.insuranceDetail(detailId).push();
        notifySuccess(t(".success"));
      },
      [insuranceTypeDetail]
    );

    const handleClickCancel = useCallback(() => {
      insuranceStepPath.insuranceDetail(detailId).push();
    }, [detailId]);

    return {
      title: breadcrumbTitle,
      breadcrumbs,
      values,
      handleClickCancel,
      viewOnly,
    };
  })
);

export const InsuranceFormPage = enhancer(InsuranceFormComponent);
