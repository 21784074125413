import { gql } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BrowseButton,
  Button,
  Field,
  FileUpload,
  Modal,
  Select,
  TextField,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance-type";
import { PERMISSIONS } from "constants/enums/permissions";
import {
  CrossStorageClient,
  CrossStorageHub,
} from "vendor/custom-cross-storage";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import env, { appEnv } from "env";
import { TFunction } from "i18next";
import { isEmpty, isEqual } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { InsuranceList } from "./InsuranceList";

import { ReactComponent as Group } from "assets/icon/Group.svg";
import { ReactComponent as RedTrash } from "assets/icon/red_trash_opacity.svg";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { convertFileToBase64 } from "utils/helper";
import { InsurancePackageList } from "./InsurancePackageList";

const backofficeEnv = env["HOST"];

CrossStorageHub.init([
  {
    origin: new RegExp(backofficeEnv),
    allow: ["get", "set"],
  },
  {
    origin: new RegExp(appEnv.HOST),
    allow: ["get"],
  },
]);

const OutlinedButton = styled(Button)`
  height: 34px;
  min-width: 151px;
  border-color: ${AppColor["Primary/Primary"]} !important;
  color: ${AppColor["Primary/Primary"]};

  &.MuiButton-outlined.Mui-disabled {
    border-color: ${AppColor["Text/Line"]} !important;
  }
`;

interface GroupPanelProps {
  attendeeGroups: any[];
  expandedList: any[];
  options: any[];
  name: string;
  handleExpand: Function;
  t: TFunction;
  insuranceTypes: any[];
  viewOnly: boolean;
  disablePreventLeaveDirtyForm: Function;
  getInsurancePlansByType: (attendeeGroup: any, typeId: string) => any[];
  hasEditPermission: boolean;
  handlePreview: any;
  masterInsurancePlans: any[];
  addDocument: any;
  removeDocument: any;
  isUploaded: any;
  handleChangeType: (index: number, e: any) => void;
  handleChangeMasterBenefitInsurancePlans: (props: any) => void;
  handleChangeMasterBenefitInsurancePackages: (props: any) => void;
  checkDisable: (index: number) => boolean;
}

const GroupPanel = (props: GroupPanelProps) => (
  <>
    {props.attendeeGroups?.map((attendeeGroup: any, index: number) => {
      const title = attendeeGroup.title;
      const selectedItem: any = props.expandedList.find(
        (item: any) => item.key === `${attendeeGroup.id}-${index}`
      );
      const expanded = selectedItem?.expanded ?? false;
      const attendeeGroupingType =
        attendeeGroup.masterBenefitInsurances[0]?.type;

      return (
        <Accordion
          expanded={expanded}
          key={title}
          onChange={() => props.handleExpand(`${attendeeGroup.id}-${index}`)}
        >
          <AccordionSummary
            expandIcon={
              <MaterialIcon
                name="ExpandMore"
                htmlColor={AppColor["Text/Dark Grey"]}
              />
            }
            style={{
              borderBottom: !expanded
                ? `1px solid ${AppColor["Background/Dark Grey"]}`
                : `0`,
            }}
          >
            <Typography variant="body1">{title}</Typography>
          </AccordionSummary>
          <Box
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="Body/16" color={AppColor["Text/Primary"]}>
              {props.t(".detail")}
            </Typography>
            <Box>
              {props.isUploaded(attendeeGroup) ? (
                <Box display="flex" alignItems="center" mt={6}>
                  <Field
                    component={FileUpload}
                    name={`${props.name}[${index}].file`}
                    pl={4}
                    simple
                    style={{ marginLeft: "auto" }}
                  />
                  <RedTrash
                    onClick={() => {
                      props.removeDocument(index);
                    }}
                    style={{
                      color: AppColor["Other/Danger"],
                      width: "16px",
                      height: "16px",
                      fillOpacity: 1,
                    }}
                  />
                </Box>
              ) : (
                <BrowseButton
                  style={{ height: "34px", minWidth: "151px" }}
                  variant="outlined"
                  onBrowse={(file: any) => {
                    props.addDocument(file, index);
                  }}
                >
                  <Group style={{ marginRight: "10px" }} />
                  <Typography variant="Body/16">{props.t(".file")}</Typography>
                </BrowseButton>
              )}
            </Box>
          </Box>
          <AccordionDetails>
            <Box mx={4} width="100%">
              <Typography variant="caption" color={AppColor["Text/Line"]}>
                {props.t(".info")}
              </Typography>

              <Box
                display="flex"
                flex={1}
                justifyContent="space-between"
                mt={4}
                mb={6}
                alignItems="center"
              >
                <Box display="flex">
                  <Box flex={1} mr={6} width={271}>
                    <Field
                      fast
                      label={props.t(".currentScore")}
                      fullWidth
                      component={TextField}
                      name={`${props.name}[${index}].point`}
                      type="number"
                      unit={props.t(".unit")}
                      required={!props.checkDisable(index)}
                      min={0}
                      disabled={
                        !props.hasEditPermission ||
                        props.viewOnly ||
                        props.checkDisable(index)
                      }
                    />
                  </Box>
                  <Box flex={1} width={271}>
                    <Field
                      fast
                      label={props.t(".type")}
                      fullWidth
                      component={Select}
                      name={`${props.name}[${index}].masterBenefitInsurances[0].type`}
                      options={props.options}
                      disabled={!props.hasEditPermission || props.viewOnly}
                      onChange={(_: any, value: any) => {
                        props.handleChangeType(index, value);
                      }}
                    />
                  </Box>
                </Box>
                <Authorize
                  permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}
                >
                  <OutlinedButton
                    variant="outlined"
                    ml={6}
                    disabled={
                      attendeeGroup.type === EnumMasterBenefitInsuranceType.none
                    }
                    onClick={() => props.handlePreview(attendeeGroup)}
                  >
                    <MaterialIcon
                      name="RemoveRedEyeOutlined"
                      style={{ marginRight: "10px" }}
                    />
                    {"Preview"}
                  </OutlinedButton>
                </Authorize>
              </Box>
              {attendeeGroupingType ===
                EnumMasterBenefitInsuranceType.custom && (
                <Box>
                  {props.insuranceTypes.map((type) => (
                    <InsuranceList
                      key={type.id}
                      label={type.name}
                      id={type.id}
                      symbol={type.symbol}
                      viewOnly={props.viewOnly}
                      masterInsurancePlans={props.masterInsurancePlans}
                      masterBenefitInsurancePlans={props.getInsurancePlansByType(
                        attendeeGroup,
                        type.id
                      )}
                      handleChangeMasterBenefitInsurancePlans={(
                        insurances: any[]
                      ) => {
                        props.handleChangeMasterBenefitInsurancePlans({
                          insurances,
                          attendeeGroup,
                          typeId: type.id,
                          index,
                        });
                      }}
                    />
                  ))}
                </Box>
              )}
              {attendeeGroupingType ===
                EnumMasterBenefitInsuranceType.package && (
                <Box>
                  <InsurancePackageList
                    label="Package ประกันที่เลือกได้"
                    attendeeGroupId={attendeeGroup.id}
                    viewOnly={props.viewOnly}
                    masterInsurancePlans={props.masterInsurancePlans}
                    masterBenefitInsurancePackages={
                      attendeeGroup.masterBenefitInsurances?.[0]
                        ?.masterBenefitInsurancePackages
                    }
                    handleChangeMasterBenefitInsurancePackages={(
                      insurances: any[]
                    ) =>
                      props.handleChangeMasterBenefitInsurancePackages({
                        insurances,
                        index,
                      })
                    }
                  />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    })}
  </>
);

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_PLANS: gql`
    query FETCH_INSURANCE_PLANS($id: String!) {
      insurancePlans(id: $id) {
        id
        nameTh
        nameEn
        remarkTh
        remarkEn
        premium
        description
        createdAt
        insuranceTypeId
        insuranceType {
          id
          nameTh
          nameEn
        }
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        year
        state
        subState
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        updatedAt
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentBackofficeUser {
        id
        firstName
        lastName
        ownerType
        email
        admin {
          id
          code
          firstName
          lastName
        }
        role {
          title
          permissions
        }
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({ prefix: "pages.main.enrollment.attendeeGrouping" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useEffect,
      useState,
      useQuery,
      useDataTranslation,
      useParams,
    } = hooks;

    const { t, form, name, hasPermission } = props;

    const { id } = useParams();
    const [expandedList, setExpandedList] = useState([]);

    const { data: enrollmentQry } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });
    const { data: currentUserQry } = useQuery(API.GET_CURRENT_USER);
    const { data: masterInsurancePlansResponse } = useQuery(
      API.FETCH_INSURANCE_PLANS,
      {
        fetchPolicy: "network-only",
        variables: { id },
      }
    );
    const { data: insuranceTypesResponse } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );

    const { values, setFieldValue } = form;
    const { attendeeGroups } = values;

    const enrollment = useMemo(() => enrollmentQry?.enrollment, [
      enrollmentQry,
    ]);
    const currentUser = useMemo(
      () => currentUserQry?.getCurrentBackofficeUser,
      [currentUserQry?.getCurrentBackofficeUser]
    );

    const addDocument = useCallback(
      (file: any, index: number) => {
        form.setFieldValue(`${name}[${index}].file`, file);
      },
      [form, name]
    );

    const removeDocument = useCallback(
      (index: number) => {
        Modal.open({
          title: t(".deletetitle"),
          children: (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1">
                  {t(".deletetitle1")} &nbsp;
                </Typography>
              </div>
            </>
          ),
          cancelButtonLabel: t(".cancel"),
          okButtonLabel: t(".deletetitle"),
          onOk: async ({ close }: any) => {
            form.setFieldValue(`${name}[${index}].file`, null);
            close();
          },
        });
      },
      [form, name, t]
    );

    const isUploaded = useCallback((attendeeGroup: any) => {
      return attendeeGroup.file;
    }, []);

    const viewOnly = useMemo(() => {
      let valid = true;

      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);

    const masterInsurancePlansTranslated = useDataTranslation(
      masterInsurancePlansResponse?.insurancePlans
    );
    const masterInsurancePlans = useMemo(() => {
      if (masterInsurancePlansTranslated) {
        return masterInsurancePlansTranslated;
      }
      return [];
    }, [masterInsurancePlansTranslated]);

    const insuranceTypesTranslated = useDataTranslation(
      insuranceTypesResponse?.insuranceTypes
    );

    const insuranceTypes = useMemo(() => {
      if (insuranceTypesTranslated) {
        return insuranceTypesTranslated;
      }
      return [];
    }, [insuranceTypesTranslated]);

    const handlePreview = useCallback(
      async (attendeeGroupParam: any) => {
        const attendeeGroup = attendeeGroups.find(
          (group: any) => group.id === attendeeGroupParam.id
        );
        const storageClient = new CrossStorageClient(backofficeEnv, {});
        const crossStorageData = {
          ...attendeeGroup,
          year: enrollment?.year,
          startDate: enrollment?.startDate,
          endDate: enrollment?.endDate,
          insuranceType: insuranceTypes,
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
        };
        if (
          attendeeGroup.file?.path &&
          attendeeGroup.file?.name &&
          attendeeGroup.file?.size &&
          attendeeGroup.file?.type
        ) {
          crossStorageData.fileBase64 = await convertFileToBase64(
            attendeeGroup.file
          );
        }

        storageClient.onConnect().then(() => {
          storageClient.set(
            "CROSS_STORAGE_DATA",
            JSON.stringify(crossStorageData)
          );
        });
        window.open(`${appEnv.HOST}/preview/${attendeeGroup.id}`);
      },
      [attendeeGroups, enrollment, insuranceTypes, currentUser]
    );

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    const handleExpand = useCallback(
      (key: string) => {
        const newExpandedList = expandedList.map((item: any) => {
          if (item.key === key) {
            return {
              ...item,
              expanded: !item.expanded,
            };
          } else {
            return item;
          }
        });
        setExpandedList(newExpandedList);
      },
      [expandedList]
    );

    const options = useMemo(() => {
      return Object.entries(EnumMasterBenefitInsuranceType).map((data) => ({
        label: t(`.${data[0]}`),
        value: data[1],
      }));
    }, [t]);

    const getInsurancePlansByType = useCallback(
      (attendeeGroup: any, typeId: string) => {
        const masterBenefitInsurancePlans =
          attendeeGroup?.masterBenefitInsurances?.[0]
            ?.masterBenefitInsurancePlans || [];

        const insurancePlansWithType = masterBenefitInsurancePlans.filter(
          (insurance: any) =>
            insurance.masterInsurancePlan.insuranceType.id === typeId
        );

        return insurancePlansWithType;
      },
      []
    );

    const handleChangeType = useCallback(
      (index: number, value: EnumMasterBenefitInsuranceType) => {
        setFieldValue(`${name}[${index}]`, {
          ...values[name][index],

          masterBenefitInsurances: [
            {
              type: value,
            },
          ],
        });
      },
      [name, setFieldValue, values]
    );

    const handleChangeMasterBenefitInsurancePlans = useCallback(
      (props: any) => {
        const { insurances, attendeeGroup, typeId, index } = props;
        const currentValues =
          attendeeGroup.masterBenefitInsurances[0]
            .masterBenefitInsurancePlans || [];

        const otherTypeValues = currentValues.filter(
          (value: any) => value.masterInsurancePlan.insuranceTypeId !== typeId
        );

        setFieldValue(
          `attendeeGroups[${index}].masterBenefitInsurances[0].masterBenefitInsurancePlans`,
          [...otherTypeValues, ...insurances]
        );
      },
      [setFieldValue]
    );

    const handleChangeMasterBenefitInsurancePackages = useCallback(
      (props: any) => {
        const { insurances, index } = props;
        setFieldValue(
          `${name}[${index}].masterBenefitInsurances[0].masterBenefitInsurancePackages`,
          insurances
        );
      },
      [name, setFieldValue]
    );
    const checkDisable = useCallback(
      (index: number) => {
        if (
          values.attendeeGroups[index].masterBenefitInsurances[0]?.type ===
          "none"
        ) {
          if (values.attendeeGroups[index].point !== null) {
            setFieldValue(`${name}[${index}].point`, null);
          }
          return true;
        }
      },
      [name, setFieldValue, values.attendeeGroups]
    );

    useEffect(() => {
      if (!isEmpty(attendeeGroups) && isEmpty(expandedList)) {
        const addedExpandedKey = attendeeGroups?.map(
          (group: any, index: number) => {
            return {
              key: `${group?.id}-${index}`,
              expanded: true,
            };
          }
        );
        setExpandedList(addedExpandedKey);
      }
    }, [attendeeGroups, expandedList]);

    return {
      attendeeGroups,
      expandedList,
      options,
      name,
      handleExpand,
      insuranceTypes,
      viewOnly,
      getInsurancePlansByType,
      hasEditPermission,
      handlePreview,
      masterInsurancePlans,
      addDocument,
      removeDocument,
      isUploaded,

      handleChangeType,
      handleChangeMasterBenefitInsurancePlans,
      handleChangeMasterBenefitInsurancePackages,
      checkDisable,
    };
  })
);

export default enhancer(GroupPanel);
