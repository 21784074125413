export const DEFAULT_LOCALE_TH: any = {
  everyText: "ทุก",
  emptyMonths: "ทุกเดือน",
  emptyMonthDays: "ทุกๆวันในแต่ละเดือน",
  emptyMonthDaysShort: "วันในแต่ละเดือน",
  emptyWeekDays: "ทุกๆวันในแต่ละสัปดาห์",
  emptyWeekDaysShort: "วันในแต่ละสัปดาห์",
  emptyHours: "ทุกชั่วโมง",
  emptyMinutes: "ทุกนาที",
  emptyMinutesForHourPeriod: "ทุก",
  yearOption: "ปี",
  monthOption: "เดือน",
  weekOption: "สัปดาห์",
  dayOption: "วัน",
  hourOption: "ชั่วโมง",
  minuteOption: "นาที",
  rebootOption: "เริ่มใหม่",
  prefixPeriod: "",
  prefixMonths: "ใน",
  prefixMonthDays: "ใน",
  prefixWeekDays: "ใน",
  prefixWeekDaysForMonthAndYearPeriod: "และ",
  prefixHours: "เวลา",
  prefixMinutes: ":",
  prefixMinutesForHourPeriod: "ณ",
  suffixMinutesForHourPeriod: "นาที",
  errorInvalidCron: "ผิดรูปแบบของ Cron Format",
  clearButtonText: "รีเซ็ต",
  weekDays: [
    // Order is important, the index will be used as value
    "อาทิตย์", // Sunday must always be first, it's "0"
    "จันทร์",
    "อังคาร",
    "พุธ",
    "พฤหัสบดี",
    "ศุกร์",
    "เสาร์",
  ],
  months: [
    // Order is important, the index will be used as value
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฏาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    "อา.", // Sunday must always be first, it's "0"
    "จ.",
    "อ.",
    "พ.",
    "พฤ.",
    "ศ.",
    "ส.",
  ],
  // Order is important, the index will be used as value
  altMonths: [
    "ม.ค",
    "ก.พ",
    "มี.ค",
    "เมษ.",
    "พฤษ.",
    "มิ.ย",
    "ก.ค",
    "ส.ค",
    "ก.ย",
    "ต.ค",
    "พ.ย",
    "ธ.ค",
  ],
};
