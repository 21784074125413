import { BaseStore } from "./index";

class AttendeeStore extends BaseStore {
  constructor() {
    super("attendeeStore", {
      attendees: [
        { id: "1", name: "Newii", group: "fulltime" },
        { id: "2", name: "Newii", group: "fulltime" },
        { id: "3", name: "Newii", group: "fulltime" },
        { id: "4", name: "Newii", group: "executive" },
        { id: "5", name: "Newii", group: "executive" },
        { id: "6", name: "Newii", group: "executive" },
        { id: "7", name: "Newii", group: "part-time" },
        { id: "8", name: "Newii", group: "part-time" },
      ],
    });
  }

  clearStore() {
    this.setState({
      attendees: [],
    });
  }
}

const attendeeStore = new AttendeeStore();

export default attendeeStore;
