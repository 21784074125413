import { Form } from "formik";
import { defaultProps } from "enhancers";

function onKeyDown(keyEvent: any) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

const enhancer = defaultProps({ onKeyDown });

export default enhancer(Form);
