import { Box, Typography } from "components";
import {
  compose,
  withAuthorize,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import env from "env";
import { PageContent } from "layouts";
import { homePath } from "utils/helper";

const MarketplaceReportComponent = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.title}
      </Typography>
      {props.reportUrl && (
        <iframe
          src={props.reportUrl}
          width="100%"
          height="1000px"
          title="Marketplace Report"
          style={{ border: "none" }}
        ></iframe>
      )}
    </Box>
  </PageContent>
);

const enhancer = compose(
  withAuthorize(),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({
    prefix: "pages.main.marketplaceReports.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { currentUser, t } = props;
    const { useMemo, useState, useEffect } = hooks;

    const [reportUrl, setReportUrl] = useState("");

    // console.log("currentUser", currentUser);
    const title = t(".reportMenu");
    const textHome = t(".home");

    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: textHome },
        { path: null, label: title },
      ];
    }, [title, textHome]);

    useEffect(() => {
      if (currentUser) {
        // const myHeaders = new Headers();
        // myHeaders.append(
        //   "authorization",
        //   "EjPHczaXBjf1HPJKB0M4Hpk3CJR6Iop6TBv9txlAJZm4fmBdD8l3VtZRmDavggmd"
        // );
        // myHeaders.append("x-tenant", "scg");
        // myHeaders.append("Content-Type", "application/json");

        const myHeaders = {
          authorization:
            "EjPHczaXBjf1HPJKB0M4Hpk3CJR6Iop6TBv9txlAJZm4fmBdD8l3VtZRmDavggmd",
          "x-tenant": "scg",
          "Content-Type": "application/json",
        };

        const raw = JSON.stringify({
          userPrincipalName: currentUser.email,
        });

        const requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
          // mode: "no-cors",
        };
        const reportLoginUrl = env["MARKETPLACE_ENDPOINT"] + "/api/auth/login";

        fetch(reportLoginUrl, requestOptions)
          .then((response) => response.json())
          .then((res) => setReportUrl(res.url as string))
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
      }
    }, [currentUser]);

    return {
      title,
      breadcrumbs,
      reportUrl,
    };
  })
);

export default enhancer(MarketplaceReportComponent);
