import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Field,
  FieldArray,
  Form,
  Grid,
  Modal,
  TextField,
  Typography,
} from "components";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import { Yup, gql, homePath, notifySuccess, paths } from "utils/helper";

import insuranceStore from "stores/insuranceStore";
import { InsuranceSelector } from "./InsuranceSelector";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { insuranceStepPath } from "../insuranceRoutes";
import { history } from "components/common/BrowserRouter";
import { PackagePricingType } from "constants/enums/package-pricing-type";
import Select from "components/common/Select";
import { isEmpty } from "lodash";

export const InsurancePackagesNewPage = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box>
      <Typography variant="h4" mb={6}>
        {props.t(".newPackageHeader")}
      </Typography>
      <Form>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={8}>
            <Field
              component={TextField}
              name="nameTh"
              label={props.t(".packageNameTh")}
              required
              fullWidth
              mb={6}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Field
              component={Select}
              label="การคิดราคา Package"
              name="packagePricingType"
              options={props.packagePricingOptions}
              required
              mb={6}
            />
          </Grid>
        </Grid>
        <Field
          component={TextField}
          name="nameEn"
          label={props.t(".packageNameEn")}
          required
          fullWidth
          mb={6}
        />
        {props.insuranceType && props.insuranceType.length > 0 && (
          <FieldArray
            name="insurances"
            component={InsuranceSelector}
            insuranceType={props.insuranceType}
            viewState={props.viewInsuranceState}
            packageIndex={props.packageIndex}
          />
        )}

        <Field
          component={TextField}
          name="remarkTh"
          label={props.t(".remarkTh")}
          // required
          fullWidth
          mb={6}
        />
        <Field
          component={TextField}
          name="remarkEn"
          label={props.t(".remarkEn")}
          // required
          fullWidth
          mb={6}
        />
        <Box display="flex" mt={10}>
          <Button
            width={74}
            p={0}
            mr={2}
            onClick={props.onBack}
            variant="outlined"
          >
            {props.t(".cancel")}
          </Button>
          <Button type="submit" width={74} p={0} variant="contained">
            {props.t(".save")}
          </Button>
        </Box>
      </Form>
    </Box>
  </PageContent>
);

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
  })),
  withFormik({
    mapPropsToValues: () => ({
      nameTh: "",
      nameEn: "",
      packagePricingType: "",
      insurances: ["", "", ""],
      remarkTh: "",
      remarkEn: "",
    }),
    validationSchema: Yup.object().shape({
      nameTh: Yup.string().required("ต้องไม่เว้นว่างเอาไว้"),
      nameEn: Yup.string().required("ต้องไม่เว้นว่างเอาไว้"),
      packagePricingType: Yup.string()
        .required("ต้องไม่เว้นว่างเอาไว้")
        .nullable(true),
      // insurances: Yup.array().of(
      //   Yup.string().required("ต้องไม่เว้นว่างเอาไว้")
      // ),
    }),
  }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withPreventLeaveDirtyForm({ onOk: () => history.goBack() }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useParams,
      useQuery,
      useMemo,
      useCallback,
    } = hooks;

    const { t } = props;

    const { insuranceId } = useParams();
    const packagePricingOptions = useMemo(() => {
      const options = Object.keys(PackagePricingType).map((key) => {
        const packagePricingType =
          PackagePricingType[key as keyof typeof PackagePricingType];
        const label = t(`.${packagePricingType}`);
        const value = key;
        return { label, value };
      });
      return options;
    }, [t]);

    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );
    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          insuranceStore.initialMasterInsurance(response);
        },
        fetchPolicy: "network-only",
      }
    );

    const insuranceType = useMemo(() => types?.insuranceTypes || [], [
      types?.insuranceTypes,
    ]);

    const title = t(".add");

    useHandleSubmit(
      async (values: any) => {
        const clearEmptyInsurances = values.insurances.filter(
          (value: any) => !isEmpty(value)
        );

        if (clearEmptyInsurances.length > 0) {
          insuranceStore.saveInsurancePackageTemp(values);
          onBack();
          notifySuccess(t(".success"));
        } else {
          Modal.alert({
            title: "ไม่สามารถบันทึกได้",
            children: "กรุณาเลือกแบบประกันอย่างน้อย 1 ประเภท",
          });
        }
      },
      [insuranceId]
    );
    const onBack = useCallback(
      () => insuranceStepPath.insurancePackage(insuranceId).push(),
      [insuranceId]
    );

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: insuranceStepPath.insurancePackage(insuranceId).toString(),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: title },
      ];
    }, [t, insuranceId, config, title]);

    return {
      onBack,
      title,
      breadcrumbs,
      viewInsuranceState: "new",
      t,
      insuranceType,
      packagePricingOptions,
    };
  })
);

export default enhancer(InsurancePackagesNewPage);
