import { compose, withHooks, withTranslation, withFormik } from "enhancers";
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Box, Typography, Field, Select, Form, Button } from "components";
import { gql, paths } from "utils/helper";
import styled from "styled-components";
import { AppColor } from "theme/app-color";

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    margin: 1vw;
    min-width: 280px;
    overflow: hidden;
  }
`;

const AddInsuranceModal = (props: any) => (
  <Dialog open={props.isOpen} fullWidth>
    <Form>
      <DialogTitle>
        <Typography variant="h4">{props.t(".addButton")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" color="#767676">
            {props.t(".modalDescription")}
          </Typography>
          <Box mt={6} mb={6}>
            <Field
              component={Select}
              name="year"
              type="text"
              label={props.t(".year")}
              options={props.yearOptions}
              fast={false}
              required
              fullWidth
              disableClearable={true}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={props.handleClose}
            style={{ border: "none" }}
            mr="16px"
            variant="text"
          >
            {props.t(".cancel")}
          </Button>
          <Button type="submit" variant="outlined">
            {props.t(".addButton")}
          </Button>
        </Box>
      </DialogActions>
    </Form>
  </Dialog>
);

export const API = {
  CREATE_MASTER_INSURANCE: gql`
    mutation CREATE_MASTER_INSURANCE($year: Float!) {
      createMasterInsurance(input: { year: $year }) {
        id
        year
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      year: null,
    }),
  }),
  withTranslation({ prefix: "pages.main.insurances.index" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useCallback, useMutation, useHandleSubmit } = hooks;
    const [createInsurance] = useMutation(API.CREATE_MASTER_INSURANCE, {
      onCompleted: (data: any) => {
        paths.insuranceDetailPath(data.createMasterInsurance.id).push();
      },
    });
    const { isOpen, onCancel } = props;

    const handleClose = useCallback(() => {
      onCancel();
    }, [onCancel]);

    useHandleSubmit(
      async (values: any) => {
        await createInsurance({
          variables: { year: values.year },
        });
      },
      [createInsurance]
    );

    const currentYear = new Date().getFullYear();
    const yearOptions = useMemo(() => {
      return [
        { value: currentYear - 5, label: (currentYear - 5).toString() },
        { value: currentYear - 4, label: (currentYear - 4).toString() },
        { value: currentYear - 3, label: (currentYear - 3).toString() },
        { value: currentYear - 2, label: (currentYear - 2).toString() },
        { value: currentYear - 1, label: (currentYear - 1).toString() },
        { value: currentYear, label: currentYear.toString() },
        { value: currentYear + 1, label: (currentYear + 1).toString() },
      ];
    }, [currentYear]);

    return { yearOptions, isOpen, handleClose };
  })
);

export default enhancer(AddInsuranceModal);
